.discount-tag {
	@tag-border-radius: 25px;

	display: 				flex;
	align-items: 			center;
	justify-content: 		space-between;
	padding: 				0 8px;
	background-color: 		@secondary-dark-blue;
	color: 					@white;
	border-radius: 			0;
	box-sizing: 			border-box;
	position: 				relative;
	height: 				30px;

	&.--small {
		height: 20px;
	}

	&:before {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		border: 10px solid @secondary-dark-blue;
		border-left-width: 9px;
		border-bottom-width: 10px;
		border-right-width: 2px;
		border-top-width: 10px;
		z-index: 1;
		left: -10px;
		border-left-color: transparent;
	}

	&.--pill {
		border-radius: 	@tag-border-radius;
		padding: 		8px 16px;

		&:before {
			border: 0;
		}
	}

	&.--inline {
		display: 			inline-flex;
		vertical-align: 	middle;
	}

	> div {
		display: 			flex;
		align-items: 		center;
		justify-content: 	flex-start;
	}

	&__image {
		max-width: 			1.125rem;
		margin-right: 		.675rem;

		&.--small {
			max-width: 		.875rem;
			margin-right: 	.3125rem;
		}
	}

	&__meta {
		color: 				@white;
		line-height: 		unset;
	}
}
