/* Global */
a {
  text-decoration: none;
  color: #00A7FF;
}
[ng-click] {
  cursor: pointer;
}
.sandbox-env {
  position: absolute;
  z-index: 50000;
  bottom: 0;
  right: 0;
  color: #ff0000;
  font-size: 10px;
  font-weight: 500;
  padding: 5px;
}
.loading-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 10px 30px;
  text-align: center;
}
.prizeout-widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'Lato', 'Roboto', sans-serif;
  font-weight: 400;
  background: #fcfdff;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.prizeout-widget .ui-header {
  background-color: #00A7FF;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 1rem;
  box-sizing: border-box;
  width: 100%;
  z-index: 51000;
  position: relative;
}
.prizeout-widget .ui-header__inner {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
@media only screen and (min-width: 1600px) {
  .prizeout-widget .ui-header__inner {
    padding: 1rem 9rem;
  }
}
@media only screen and (max-width: 1440px) {
  .prizeout-widget .ui-header__inner {
    padding: 1rem 2rem;
  }
}
@media only screen and (max-width: 1024px) {
  .prizeout-widget .ui-header__inner {
    padding: 1rem 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .prizeout-widget .ui-header__inner {
    padding: .7rem 1rem;
  }
}
.prizeout-widget .ui-header__inner > div {
  flex: 1;
  width: 100%;
}
.prizeout-widget .ui-header .back {
  text-align: left;
}
.prizeout-widget .ui-header .back__icon {
  color: #FFFFFF;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.prizeout-widget .ui-header .back__icon:hover {
  color: #021431;
}
.prizeout-widget .ui-header .logo {
  text-align: center;
}
.prizeout-widget .ui-header .logo__image {
  width: 100%;
  max-width: 140px;
  height: auto;
  display: inline-block;
}
.prizeout-widget .ui-header .logo__image.--mobile {
  max-width: 20px;
}
.prizeout-widget .ui-header .user {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.prizeout-widget .ui-header .user__balance {
  background-color: #FFFFFF;
  border-radius: 30px;
  font-family: Karla, Arial, sans-serif;
  padding: .25rem .9rem;
  margin-right: .75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.prizeout-widget .ui-header .user__balance p {
  color: #021431;
  font-size: 1rem;
}
@media only screen and (max-width: 960px) {
  .prizeout-widget .ui-header .user__balance p {
    font-size: .875rem;
  }
}
@media only screen and (max-width: 480px) {
  .prizeout-widget .ui-header .user__balance p {
    font-size: .65rem;
  }
}
.prizeout-widget .ui-header .user__bars {
  color: #FFFFFF;
  font-size: 1.5rem;
}
@media only screen and (max-width: 480px) {
  .prizeout-widget .ui-header .user__bars {
    font-size: 1.2rem;
  }
}
.prizeout-widget > .ui-content {
  overflow-y: auto;
  overflow-x: hidden;
  color: #021431;
  background-color: #f2f3f4;
  -webkit-overflow-scrolling: touch;
}
.prizeout-widget > .ui-content > .content-container {
  transition: 0.3s ease-out all;
  padding-right: 0;
}
.scroll-pane {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.side-panel-layout {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 3rem;
  box-sizing: border-box;
  background-color: #F3F3F6;
  transition: 0.3s ease-in all;
  overflow-x: hidden;
}
@media only screen and (min-width: 1600px) {
  .side-panel-layout {
    padding: 3rem 9rem;
  }
}
@media only screen and (max-width: 1440px) {
  .side-panel-layout {
    padding: 3rem 2rem;
  }
}
@media only screen and (max-width: 1024px) {
  .side-panel-layout {
    padding: 3rem 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .side-panel-layout {
    padding: 2rem 1rem;
  }
}
.side-panel-layout > div > div.ui-content {
  color: #021431;
  background-color: #F3F3F6;
  -webkit-overflow-scrolling: touch;
}
.side-panel-layout > div > div.side-panel-container {
  transition: 0.3s ease-out all;
  width: 0;
  -webkit-overflow-scrolling: touch;
}
.side-panel-layout.side-panel-open {
  width: calc((100% - min(38vw, 25rem)) + 1rem);
  padding-right: 2.25rem;
}
@media only screen and (min-width: 1600px) {
  .side-panel-layout.side-panel-open {
    padding-right: 5rem;
  }
}
@media only screen and (max-width: 1440px) {
  .side-panel-layout.side-panel-open {
    padding-right: 2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .side-panel-layout.side-panel-open {
    padding-right: 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .side-panel-layout.side-panel-open {
    padding-right: 1rem;
  }
}
.side-panel-layout.side-panel-open > div > div.side-panel-container {
  width: min(38vw, 25rem);
}
.ui-footer {
  text-align: center;
  padding: 10px 25px;
  background-color: #222222;
}
.ui-footer .partner-text {
  font-size: 6px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}
.ui-footer .footer-menu .item {
  display: inline-block;
  vertical-align: middle;
  font-size: 9px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}
.ui-footer .footer-menu .sep {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.8);
}
.ui-footer .data-request {
  display: inline-block;
  vertical-align: middle;
  font-size: 9px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}
.ng-highlight {
  background-color: rgba(66, 165, 245, 0.3);
}
/*
	OneTrust
*/
button {
  margin: 0;
}
.ot-btn-anchor {
  text-decoration: none;
}
.ot-exercise-button-light {
  background: #eee;
  border: 1px solid #aaa;
  /*margin-left: 44px;*/
}
.ot-exercise-button {
  margin-top: 10px;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.ot-exercise-button img {
  margin-right: 0px;
  vertical-align: middle;
}
.ot-exercise-button .ot-text-container {
  vertical-align: middle;
  display: inline-block;
  margin-top: -4px;
  color: #6cc04a;
  font-size: 13px;
}
.ot-exercise-button .ot-subtext {
  float: right;
  margin-top: 0px;
  font-size: 11px;
}
.ot-exercise-button .ot-powered-by-text {
  font-size: 7px;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  line-height: 16px;
}
.ot-exercise-button.ot-exercise-button-light .ot-powered-by-text {
  color: #3c474c;
}
table.my-giftcards {
  width: 100%;
}
table.my-giftcards > tbody > tr > td {
  padding: 10px 5px;
}
table.my-giftcards > tbody > tr > td.image {
  width: 100px;
}
table.my-giftcards > tbody > tr > td.image > .giftcard-image {
  width: 123px;
  height: 78px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #dcdddf;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
table.my-giftcards > tbody > tr > td.gc-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
  color: #4f566b;
  font-size: 12px;
  font-weight: 500;
}
table.my-giftcards > tbody > tr > td.gc-name .name {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
table.my-giftcards > tbody > tr > td.gc-name .detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .when {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 10px;
  font-weight: 300;
  border-right: 1px solid #e1e8eb;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .when > span {
  margin-bottom: 5px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value {
  width: 40%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 16px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value > span {
  width: 80%;
  margin-right: 10px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value .fa-caret-right,
table.my-giftcards > tbody > tr > td.gc-name .detail .value .fa-caret-down {
  font-size: 20px;
}
table.my-giftcards > tbody > tr > td.status {
  text-align: center;
}
table.my-giftcards > tbody > tr > td.status a {
  font-size: 10px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.7);
}
table.my-giftcards > tbody > tr.last td {
  border-bottom: 1px solid #6c748e;
}
table.transaction-detail {
  width: 100%;
  padding: 5px;
}
table.transaction-detail > tbody tr {
  font-size: 12px;
}
table.transaction-detail > tbody tr td {
  padding-bottom: 5px;
}
table.transaction-detail > tbody tr td:first-child {
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
}
table.transaction-detail > tbody tr td.detail {
  color: #4f566b;
}
.text-link {
  color: #1eb1fa;
  text-decoration: underline;
}
.ui-pagination > table {
  width: 100%;
}
.ui-pagination > table > tbody > tr > td {
  padding: 5px;
  text-align: center;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li {
  display: inline-block;
  padding: 3px 8px;
  font-size: 15px;
  font-weight: 500;
  color: #4f566b;
  border-radius: 3px;
  transition: 0.15s ease-in-out;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li:hover {
  background: #f4f5f7;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li.active {
  font-weight: 600;
  background-color: #42a5f5;
  color: #ffffff;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li.disabled {
  pointer-events: none;
  color: #00000029;
}
.ui-nav {
  /*
		Large bubble menu:
		https://i.imgur.com/bWc7rTK.png
	*/
  /*
		Medium bubble toggle
	*/
  /*
		CTAs: Large buttons in a line
	*/
}
.ui-nav.bubble-menu {
  text-align: center;
  margin: 30px 0;
}
.ui-nav.bubble-menu > a {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #F8F9FC;
  width: 180px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
}
.ui-nav.bubble-menu > a > .icon {
  text-align: center;
  font-size: 45px;
  color: #42A5F5;
  transition: color 0.2s ease-out;
}
.ui-nav.bubble-menu > a > .label {
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.ui-nav.bubble-menu > a:hover {
  background-color: rgba(3, 155, 229, 0.05);
  text-decoration: none;
  transform: scale(1.2);
}
.ui-nav.bubble-menu > a:hover > .icon {
  color: #29B6F6;
}
.ui-nav.bubble-toggle {
  text-align: center;
  margin: 30px 0;
}
.ui-nav.bubble-toggle > div {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: #F8F9FC;
  width: 150px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}
.ui-nav.bubble-toggle > div > .icon {
  text-align: center;
  font-size: 35px;
  color: #42A5F5;
  transition: color 0.2s ease-out;
}
.ui-nav.bubble-toggle > div > .label {
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.ui-nav.bubble-toggle > div:hover {
  background-color: rgba(3, 155, 229, 0.05);
  text-decoration: none;
}
.ui-nav.bubble-toggle > div:hover > .icon {
  color: #29B6F6;
}
.ui-nav.bubble-toggle > div.selected {
  background-color: #42a5f5;
  color: #ffffff;
  text-decoration: none;
}
.ui-nav.bubble-toggle > div.selected > .icon {
  color: #ffffff;
}
.ui-nav.bubble-toggle > div.selected > .label {
  color: #ffffff;
  font-weight: 700;
}
.ui-nav.ctas {
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  /*&.scroll-fixed {
			position:	absolute;
		}*/
}
.ui-nav.ctas div,
.ui-nav.ctas a {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  margin: 10px;
  border-radius: 5px;
  padding: 0 25px;
  background-color: #1EB1FA;
  color: #ffffff;
  font-size: 14px;
  line-height: 45px;
  font-weight: 600;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
  position: relative;
}
.ui-nav.ctas div:hover,
.ui-nav.ctas a:hover {
  background-color: #128ef2;
  text-decoration: none;
}
.ui-nav.ctas div.main,
.ui-nav.ctas a.main {
  background-color: #1eb1fa;
}
.ui-nav.ctas div.main:hover,
.ui-nav.ctas a.main:hover {
  background-color: #128ef2;
  text-decoration: none;
}
.ui-nav.ctas div.blank,
.ui-nav.ctas a.blank {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #333333;
}
.ui-nav.ctas div.blank:hover,
.ui-nav.ctas a.blank:hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.ui-nav.ctas div.danger,
.ui-nav.ctas a.danger {
  background-color: #e57373;
  color: #ffffff;
}
.ui-nav.ctas div.danger:hover,
.ui-nav.ctas a.danger:hover {
  background-color: #dd4848;
  text-decoration: none;
}
.ui-nav.ctas div.admin,
.ui-nav.ctas a.admin {
  background-color: #78909c;
  color: #ffffff;
}
.ui-nav.ctas div.admin:hover,
.ui-nav.ctas a.admin:hover {
  background-color: #5f7682;
  text-decoration: none;
}
.ui-nav.ctas div.positive,
.ui-nav.ctas a.positive {
  background-color: #66bb6a;
  color: #ffffff;
}
.ui-nav.ctas div.positive:hover,
.ui-nav.ctas a.positive:hover {
  background-color: #49a54e;
  text-decoration: none;
}
.ui-nav.ctas div.negative,
.ui-nav.ctas a.negative {
  background-color: #fb8c00;
  color: #ffffff;
}
.ui-nav.ctas div.negative:hover,
.ui-nav.ctas a.negative:hover {
  background-color: #c87000;
  text-decoration: none;
}
.ui-nav.ctas div.disabled,
.ui-nav.ctas a.disabled {
  background-color: rgba(0, 0, 0, 0.5);
  color: #333333;
  text-decoration: none;
  cursor: default;
}
.ui-nav.ctas div.disabled:hover,
.ui-nav.ctas a.disabled:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #333333;
  text-decoration: none;
  cursor: default;
}
.ui-nav.ctas div.sep,
.ui-nav.ctas a.sep {
  padding: 0 !important;
  width: 1px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: default;
}
.ui-nav.ctas div .alert-dot,
.ui-nav.ctas a .alert-dot {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;
}
.ui-nav.ctas.small div,
.ui-nav.ctas.small a {
  font-size: 12px;
  line-height: 30px;
  padding: 2px 30px;
  margin: 5px;
}
/* Fonts */
/* Colors */
/* Border Radius */
/* Box Shadow */
/* Homepage Layout */
/* Media Queries
	usage: @media @tablet-landscape-down { ... };
*/
body,
h1,
h2,
h3,
h4,
p,
small,
.checkout-page .ui-offer-page .checkout__description > * {
  margin: 0;
  font-family: Lato, Arial, sans-serif;
  color: #021431;
}
h1,
h2,
h3,
h4 {
  font-weight: 600;
  line-height: 1.2;
}
p,
small,
span,
.checkout-page .ui-offer-page .checkout__description > * {
  font-weight: 400;
  line-height: 1.4;
}
strong {
  color: inherit;
  font-size: inherit;
  font-weight: 700;
  font-weight: 600;
}
*[class*="__title"] {
  font-size: 1.25rem;
  color: #9098A5;
}
@media only screen and (max-width: 768px) {
  *[class*="__title"] {
    font-size: .875rem;
  }
}
*[class*="__heading"] {
  font-size: 1.5rem;
}
@media only screen and (max-width: 768px) {
  *[class*="__heading"] {
    font-size: 1.25rem;
  }
}
*[class*="__subheading"],
p,
.checkout-page .ui-offer-page .checkout__description > * {
  font-size: 1rem;
}
@media only screen and (max-width: 768px) {
  *[class*="__subheading"],
  p,
  .checkout-page .ui-offer-page .checkout__description > * {
    font-size: .875rem;
  }
}
*[class*="__name"] {
  font-size: .6rem;
  color: #9098A5;
}
small {
  font-size: .7rem;
}
small[class*="__subheading"] {
  font-size: .875rem;
}
[class*="__meta"] {
  font-size: .6rem;
}
[class*="__meta"].--small {
  font-size: .5rem;
}
[class*="__meta"].--large {
  font-size: .8rem;
}
*[class*="__callout"] {
  font-size: .75rem;
  font-weight: 600;
  color: inherit;
}
*[class*="__option"] {
  color: #808998;
  font-size: .7rem;
}
.description,
*[class*="__description"],
.checkout-page .ui-offer-page .checkout__description > * {
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  color: #9098A5;
}
.nobreak {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-text-underline {
  text-decoration: none !important;
}
.link {
  font-size: 10px;
  font-weight: 500;
  color: rgba(66, 165, 245, 0.7);
  transition: color ease-out 0.3s;
}
.link:hover {
  text-decoration: underline;
}
.loading-indicator {
  padding: 20px auto;
  font-size: 45px;
  color: #000000;
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italicize {
  font-style: italic;
}
.help-link {
  text-align: right;
  padding: 3px 10px;
}
.help-link > span {
  color: #42a5f5;
  font-size: 11px;
  font-weight: 600;
}
.font-weight-bolder {
  font-weight: 800;
}
/*
	Alert Boxes
*/
.ui-alert {
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.ui-alert.warning {
  background-color: rgba(255, 193, 7, 0.8);
  color: #333;
}
/* Full screen overlay */
.ui-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 51000;
}
.ui-overlay.ng-enter {
  animation: fadeIn 0.4s ease-out;
  -webkit-animation: fadeIn 0.4s ease-out;
}
.ui-overlay.ng-leave {
  animation: fadeOut 0.3s linear;
  -webkit-animation: fadeOut 0.3s linear;
}
/*
	Copy-pasted from Quant-Studio.com with authorization
*/
.app-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table /*!important*/;
  z-index: 51000;
  /* navbar is 50000 */
  transition: opacity ease-out 0.3s, transform ease-out 0.3s;
  /* Dialog bg */
}
.app-dialog.gdpr {
  top: 65px;
  height: calc(100% - 65px);
}
@media only screen and (max-width: 960px) {
  .app-dialog.gdpr {
    bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .app-dialog.gdpr {
    top: 48px;
    height: calc(100% - 48px);
  }
}
.app-dialog.gdpr .ctas > div {
  width: 180px !important;
}
.app-dialog.gdpr .ctas > div.disabled {
  opacity: 0.5;
}
.app-dialog.gdpr .exit {
  background: #acacac;
}
.app-dialog.gdpr .exit:hover {
  background: #0000009f;
}
.app-dialog.ng-enter,
.app-dialog.ng-leave.ng-leave-active {
  opacity: 0;
}
.app-dialog.ng-leave,
.app-dialog.ng-enter.ng-enter-active {
  opacity: 1;
}
.app-dialog.front {
  z-index: 52000;
}
.app-dialog > .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.app-dialog .overlay {
  position: relative;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.app-dialog .overlay > div {
  max-width: 500px;
  padding: 60px;
  margin: 0 auto;
  text-align: left;
  border-radius: 5px;
}
.app-dialog .overlay.form > div {
  max-width: 500px;
}
.app-dialog .overlay.medium > div {
  max-width: 500px;
}
.app-dialog .overlay.large > div {
  max-width: 800px;
}
.app-dialog.small > .overlay > div {
  max-width: 400px;
}
.app-dialog.medium > .overlay > div {
  max-width: 600px;
}
.app-dialog.large .overlay > div {
  max-width: 800px;
}
.app-dialog.x-large > .overlay > div {
  max-width: 90%;
}
.app-dialog-ui {
  background-image: linear-gradient(#FEFEFF, #F9F8FF);
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.app-dialog-ui .header {
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  padding: 30px 10px 10px;
  text-align: center;
}
.app-dialog-box {
  background-color: #ffffff;
  border-radius: 5px;
}
.app-dialog-box .body {
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  /*padding:			10px;*/
  border-bottom: 1px solid #20222E;
  border-radius: 5px;
}
.app-dialog-box .body.gdpr {
  color: #6d7798;
  padding: 1rem;
  text-align: center;
}
.app-dialog-box .body.gdpr .logo {
  max-height: 48px;
}
.app-dialog-box .body.gdpr .text {
  margin: 8px 0;
  color: #6d7798;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.app-dialog-box .body.gdpr .info-block {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.app-dialog-box .body.gdpr .info-block a {
  color: #0000009f;
  font-weight: 600;
  text-decoration: underline;
}
.app-dialog-box .body.gdpr .info-block a:hover {
  text-decoration: none;
}
.app-dialog-box .body.gdpr .checklist-container {
  display: flex;
  flex-direction: column;
  margin: 0 4rem 2rem 4rem;
}
@media screen and (max-width: 480px) {
  .app-dialog-box .body.gdpr .checklist-container {
    margin: 0;
  }
}
.app-dialog-box .body.gdpr .checklist-container .checklist-item > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
}
.app-dialog-box .body.gdpr label {
  position: relative;
  display: block;
  margin-right: 1rem;
  margin-bottom: 12px;
  padding-left: 24px;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
}
.app-dialog-box .body.gdpr label input {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
  opacity: 0;
}
.app-dialog-box .body.gdpr label input:checked ~ .checkmark {
  background-color: #ffffff;
}
.app-dialog-box .body.gdpr label input:checked ~ .checkmark:after {
  display: block;
}
.app-dialog-box .body.gdpr label .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid #dcdddf;
  border-radius: 6px;
}
.app-dialog-box .body.gdpr label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: -11px;
  width: 6px;
  height: 18px;
  border: solid #1eb1fa;
  border-width: 0 3px 3px 0;
  transform: rotate(30deg);
}
.app-dialog-box .body.gdpr .ctas .button {
  font-weight: 500;
  letter-spacing: .5px;
  box-shadow: none;
}
.app-dialog-box .body.gdpr .ctas .button.disabled {
  color: #ffffff;
  background: #1eb1fa !important;
}
.app-dialog-box .body .text {
  font-size: 14px;
  font-weight: 400;
}
.app-dialog-box .body.no-padding {
  padding: 0;
}
.app-dialog-box .body.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.app-dialog-box .logo {
  max-height: 100px;
}
.app-dialog-box .message {
  text-align: center;
  padding: 3px;
  font-size: 11px;
  background-color: #508ED7;
  color: #333333;
}
.app-dialog-box .message a {
  font-size: 10px;
  text-decoration: underline;
}
.app-dialog-box .header {
  background-color: #1F2229;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid #20222E;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
.app-dialog-box .header > .author {
  color: #1CC5BC;
  font-size: 11px;
  font-weight: 500;
}
.app-dialog-box .header.with-tabs {
  position: relative;
}
.app-dialog-box .header.with-tabs .dialog-header-tabs {
  position: absolute;
  top: 10px;
  right: 10px;
}
.app-dialog-box .menu {
  background-color: #2D2F3B;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid #20222E;
}
.app-dialog-box .tabs {
  background-color: #2D2F3B;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid #20222E;
  text-align: center;
}
.app-dialog-box .tabs > div {
  display: inline-block;
  padding: 5px 15px;
  margin: 4px 0;
  color: #000000;
  border-bottom: 2px solid transparent;
  /*transition:		border-bottom 0.2s linear, color 0.3s linear;*/
}
.app-dialog-box .tabs > div.selected {
  border-bottom: 2px solid #508ED7;
  color: #333333;
}
.app-dialog-box .tabs > div:hover {
  color: #333333;
}
.app-dialog-box .tabs > div .tag {
  font-size: 8px;
}
.app-dialog-box .dialog-close {
  text-align: right;
}
.app-dialog-box .dialog-close .fa-times,
.app-dialog-box .dialog-close .fa-times-circle {
  margin-top: 5px;
  margin-right: 5px;
  color: lightgray;
  font-size: 30px;
  font-weight: 300;
  transition: 0.25s ease-in;
}
.app-dialog-box .dialog-close .fa-times:hover,
.app-dialog-box .dialog-close .fa-times-circle:hover {
  color: #000000;
}
.app-dialog-box .body {
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  /*padding:			10px;*/
  border-bottom: 1px solid #20222E;
  border-radius: 0 0 5px 5px;
}
.app-dialog-box .body .title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}
.app-dialog-box .body .info {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}
.app-dialog-box .body .text {
  font-size: 12px;
  font-weight: 400;
}
.app-dialog-box .body .error {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #B71C1C;
}
.app-dialog-box .body .fake-link {
  text-decoration: underline;
  font-weight: 700;
}
.app-dialog-box .body.no-padding {
  padding: 0;
}
.app-dialog-box .body.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.app-dialog-box .confirmation-message {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
.app-dialog-box.static-height .body {
  height: 400px;
  overflow-y: auto;
}
.app-dialog-box .text-block {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  text-align: left;
}
.app-dialog-box .text-block.center {
  text-align: center;
}
.app-dialog-box .text-block.right {
  text-align: right;
}
.app-dialog-box .text-block a {
  color: #508ED7;
  text-decoration: underline;
}
.app-dialog-box .footer {
  background-color: #508ED7;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  color: #333333;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 5px 0;
}
.app-dialog-box .footer:last-child {
  margin: 5px 0 0 0;
}
.app-dialog-box .footer:hover {
  background-color: #3b81d2;
}
.app-dialog-box .footer.green {
  background-color: #5BB85D;
}
.app-dialog-box .footer.green:hover {
  background-color: #4bae4e;
}
.app-dialog-box .footer.disabled,
.app-dialog-box .footer.disabled:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.2);
  cursor: default;
}
.app-dialog-box .text-link {
  color: #333333;
  font-size: 11px;
  font-weight: 300;
  padding: 10px;
  text-align: center;
  margin: 5px 0;
}
.ui-tags {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  cursor: text;
  /*position:			relative;*/
}
.ui-tags > input {
  display: inline-block;
  height: 20px;
  border: 0;
  background-color: transparent;
  width: 20px;
}
.ui-tags > input:focus {
  outline: none;
}
.ui-tags > div {
  position: relative;
}
.ui-tags > div .ui-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}
.tag {
  background-color: #42A5F5;
  color: #ffffff;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
  margin: 1px;
}
.tag.green {
  background-color: #66bb6a;
}
.tag.red {
  background-color: #d32f2f;
}
.tag.orange {
  background-color: #ff9800;
}
.tag.blue {
  background-color: #03a9f4;
}
.tag.gray {
  background-color: #78909c;
}
.tag.disabled {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.7);
}
.tag > span {
  display: inline-block;
  vertical-align: middle;
}
.tag > .label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
  vertical-align: middle;
}
.tag.small {
  padding: 1px 3px;
  line-height: 14px;
  font-size: 8px;
}
.ui-list {
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.ui-list.absolute {
  position: absolute;
  width: 100%;
}
.ui-list > div {
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  vertical-align: top;
}
.ui-list > div > .icon {
  float: left;
  margin-right: 5px;
}
.ui-list > div > .with-help {
  float: left;
}
.ui-list > div > .with-help > .main {
  font-size: 14px;
  font-weight: 700;
}
.ui-list > div > .with-help > .sub {
  font-size: 11px;
  font-weight: 500;
}
.ui-list > div.active {
  background-color: #cce8ff;
}
.ui-list > div:hover {
  background-color: rgba(66, 165, 245, 0.1);
}
.ui-checklist {
  background-color: #ffffff;
  overflow: auto;
  border-radius: 3px;
  display: table;
  width: 100%;
}
.ui-checklist > div {
  display: table-row;
}
.ui-checklist > div > div {
  display: table-cell;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  vertical-align: middle;
}
.ui-checklist > div > div.icon {
  width: 20px;
  text-align: left;
}
.ui-checklist > div > div.label > .main {
  font-size: 12px;
  font-weight: 700;
}
.ui-checklist > div > div.label > .sub {
  font-size: 11px;
  font-weight: 500;
}
.ui-checklist > div.active {
  background-color: #cce8ff;
}
.ui-checklist > div:hover {
  background-color: rgba(66, 165, 245, 0.1);
}
.edit-in-place {
  position: relative;
}
.edit-in-place input.input-text {
  /*position:		absolute;
		top:			0;
		left:			0;
		height:			100%;*/
  width: 100%;
  padding: 2px 4px;
}
.edit-in-place select {
  width: 100%;
}
.edit-in-place .cell-message {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 7px;
  font-weight: 600;
  padding: 1px 3px;
  border-radius: 3px 0 0 0;
}
.edit-in-place .cell-message.accepted {
  background-color: rgba(102, 187, 106, 0.6);
  color: #ffffff;
}
.edit-in-place .cell-message.rejected {
  background-color: #e57373;
  color: #ffffff;
}
.edit-in-place .cell-message.assigned {
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.edit-in-place .cell-message.modified {
  background-color: rgba(255, 193, 7, 0.6);
  color: rgba(0, 0, 0, 0.6);
}
.edit-in-place .cell-message.new {
  background-color: rgba(66, 165, 245, 0.6);
  color: #ffffff;
}
/*
	Role editor UI
*/
.role-editor .type-ahead input {
  width: 100%;
  padding: 2px 4px;
}
.role-editor .type-ahead > div > div {
  position: relative;
}
.role-editor .type-ahead > div > div > div {
  position: absolute;
  width: 100%;
}
.role-editor > table {
  width: 100%;
}
.role-editor > table > tbody > tr {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}
.role-editor > table > tbody > tr > td {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.role-editor > table > tbody > tr > td.role {
  width: 100px;
}
.role-editor > table > tbody > tr > td.role select {
  width: 100%;
}
.role-editor > table > tbody > tr > td.del {
  width: 30px;
  text-align: center;
}
.role-editor > table > tbody > tr:last-child {
  border-bottom: 0;
}
.ui-profile img {
  float: left;
  width: 34px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.ui-profile .name {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}
.ui-profile .email {
  font-size: 10px;
  font-weight: 600;
  color: rgba(0, 156, 239, 0.8);
}
.ui-toggles {
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: repeat(4, minmax(70px, 1fr));
}
.ui-toggles > div {
  padding: 8px 5px;
  border: 1px solid rgba(2, 20, 49, 0.25);
  border-radius: 10px;
  color: #808998;
  font-size: .65rem;
  text-align: center;
}
.ui-toggles > div.selected {
  background-color: #00A7FF;
  color: #FFFFFF;
  border: 1px solid #00A7FF;
}
.ui-form {
  width: 100%;
  padding: 1.5rem 2.5rem 2.5rem;
  border-top: 1px solid #0214311a;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .ui-form {
    padding: 1rem 1.5rem 1.5rem;
  }
}
@media only screen and (max-width: 960px) {
  .ui-form {
    padding: 1rem 1.5rem;
  }
}
.ui-form > div.form {
  width: 100%;
  padding: 10px 0;
}
.ui-form > div.form > div {
  display: flex;
  align-items: center;
}
.ui-form > div.form > div > div {
  display: table-cell;
  padding: 10px;
}
.ui-form > div.form > div > div input {
  width: 82%;
  padding: 7px;
  border: 1px solid silver;
  border-radius: 3px;
  font-size: 13px;
  text-overflow: ellipsis;
}
.ui-form > div.form > div > div textarea {
  width: 100%;
  height: 96px;
}
.ui-form > div.form > div > div:first-child {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
  width: 35%;
  text-align: right;
}
.ui-form > div.form > div > div:last-child {
  width: 70%;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  text-align: left;
}
.ui-form > div.form > div > div:last-child .about {
  text-align: center;
  font-size: 11px;
  color: #5d5d5d;
  font-size: 500;
  padding: 6px 0;
}
.ui-form > div.form > div > div.special-offer {
  color: #57ba6e;
  font-size: 12px;
}
.ui-form > div.form .title {
  margin: 0 0 10px 0;
  color: #566481;
  text-align: center;
}
.ui-form > div.form.lines > div > div {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}
.ui-form > .sep {
  background-color: #e1e8eb;
  height: 1px;
  margin: 10px auto;
}
.ui-form > .ctas {
  text-align: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ui-form > .ctas .quantity-input {
  display: table;
  width: 75%;
  margin: 10px auto;
  border-bottom: 1px solid #1EB1FA;
}
.ui-form > .ctas .quantity-input > div {
  display: table-row;
}
.ui-form > .ctas .quantity-input > div > div {
  display: table-cell;
  padding: 10px 0;
}
.ui-form > .ctas .quantity-input > div > div:first-child {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #566481;
}
.ui-form > .ctas .quantity-input > div > div:last-child {
  text-align: right;
}
.ui-form > .ctas .quantity-input > div > div:last-child input {
  width: 40px;
  padding: 7px;
  border: 1px solid silver;
  border-radius: 3px;
  font-size: 13px;
  text-overflow: ellipsis;
}
.ui-form > .ctas .calculation {
  margin: 0 0 2rem 0;
}
@media only screen and (max-width: 1024px) {
  .ui-form > .ctas .calculation {
    margin: 0 0 1.5rem;
  }
}
.ui-form > .ctas .calculation > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px auto 0 auto;
  color: #021431;
  font-size: 18px;
  font-weight: 300;
}
.ui-form > .ctas .calculation > div:first-child {
  margin: 0 auto;
}
.ui-form > .ctas .calculation > div > div:first-child {
  flex: 1;
  font-weight: 400;
  text-align: left;
}
.ui-form > .ctas .calculation > div > div:last-child {
  font-weight: 400;
  text-align: right;
}
.ui-form > .ctas .calculation > div:last-child {
  border: 0;
  padding-bottom: 0;
}
.ui-form > .ctas .calculation > div:last-child > div:first-child {
  font-size: 18px;
  font-weight: 700;
}
.ui-form > .ctas .calculation > div:last-child > div:last-child {
  color: #021431;
  font-size: 18px;
  white-space: nowrap;
}
.ui-form > .ctas .calculation .sep {
  height: .5px;
  background-color: rgba(72, 84, 105, 0.9);
  margin: 15px auto;
}
@media only screen and (max-width: 480px) {
  .ui-form > .ctas .calculation .sep {
    margin: 12px auto;
  }
}
.ui-form > .ctas > div.blank {
  width: 55%;
  margin: 0 auto;
  padding: 7px 17px;
  border-radius: 3px;
  color: #707070;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}
.ui-form > .ctas > div.blank:hover {
  background: rgba(0, 0, 0, 0.1);
}
.ui-form > .ctas > div.regular {
  position: relative;
  margin: 0 auto;
  padding: 11px 17px;
  background-color: #00A7FF;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
}
@media only screen and (max-width: 960px) {
  .ui-form > .ctas > div.regular {
    font-size: .875rem;
  }
}
.ui-form > .ctas > div.regular:hover {
  background: #128ef2;
  cursor: pointer;
}
.ui-form > .ctas > div.regular:focus {
  outline: none;
}
.ui-form > .ctas > div.regular:focus > div.confirmation-button {
  opacity: 1;
}
.ui-form > .ctas > div.regular.disabled {
  opacity: 0.5;
}
.ui-form > .ctas > div.regular.confirmation-button,
.ui-form > .ctas > div.regular.confirmation-loading-button {
  background-color: #0B43A0;
}
.ui-form > .ctas > div.regular.confirmation-button:hover,
.ui-form > .ctas > div.regular.confirmation-loading-button:hover {
  background-color: #082f70;
}
.ui-form > .ctas > div.regular.confirmation-loading-button {
  pointer-events: none;
  cursor: not-allowed;
}
.ui-form > .ctas > div.regular > div.confirmation-button {
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  padding: 11px 0;
  border-radius: 10px;
  background: #0B43A0;
  color: #ffffff;
}
.ui-form > .ctas > div.regular > div.confirmation-loading-button {
  width: 100%;
  position: absolute;
  opacity: 1;
  top: 0px;
  left: 0;
  padding: 11px 0;
  border-radius: 10px;
  background: #0B43A0;
  color: #ffffff;
  pointer-events: none;
  cursor: not-allowed;
}
.ui-form > .ctas > div.regular > div.confirmation-loading-button:hover {
  background-color: #021431;
}
.ui-form .not-enough {
  position: absolute;
  top: -7px;
  color: #e70028 !important;
  font-size: 12px !important;
}
.ui-tabs {
  background-color: #444b5a;
  text-align: center;
}
.ui-tabs > div {
  display: inline-block;
  color: rgba(255, 255, 255, 0.3);
  font-weight: 600;
  font-size: 12px;
  border-bottom: 3px solid transparent;
  padding: 10px 25px;
  transition: color ease-out 0.3s, border-bottom ease-out 0.3s;
}
.ui-tabs > div.selected {
  color: #ffffff;
  border-bottom: 3px solid #42a5f5;
}
.ui-table {
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
}
.ui-table.no-scroll {
  max-height: none;
}
.ui-table > table {
  width: 100%;
}
.ui-table > table > thead > tr > th {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
}
.ui-table > table > tbody > tr > td {
  cursor: default;
  padding: 5px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
}
.ui-table > table > tbody > tr > td.name {
  max-width: 150px;
}
.ui-table > table > tbody > tr > td.date {
  max-width: 110px;
}
.ui-table > table > tbody > tr > td.profile {
  width: 200px;
}
.ui-table > table > tbody > tr > td.number {
  width: 100px;
}
.ui-table > table > tbody > tr > td.action {
  text-align: right;
}
.ui-table > table > tbody > tr > td.action > span.link {
  color: rgba(66, 165, 245, 0.6);
  font-weight: 600;
  padding: 0 5px;
  transition: color ease-out 0.3s;
}
.ui-table > table > tbody > tr > td.action > span.link:hover {
  color: #42a5f5;
}
.ui-table > table > tbody > tr:hover > td {
  background-color: rgba(0, 0, 0, 0.1);
}
.ui-table > table > tbody > tr.selected > td {
  background-color: rgba(66, 165, 245, 0.8);
  color: #ffffff;
}
.ui-table.autosize > table {
  width: auto;
  margin: 0 auto;
}
/*
	Auth
*/
.ui-auth {
  padding: 10px;
  text-align: center;
}
.ui-auth > div {
  background-color: #ffffff;
  margin: 0 auto;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.ui-auth > div > div {
  padding: 5px;
}
.ui-auth > div > div input {
  min-width: 200px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px;
}
.ui-dropdown {
  display: inline-block;
  margin: 10px auto;
}
.ui-dropdown > .label {
  background-color: #1eb1fa;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 3px 6px #d6d6d6;
  padding: 5px 15px;
  border-radius: 2px;
}
.ui-dropdown > .dropdown {
  position: relative;
}
.ui-dropdown > .dropdown > div {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  z-index: 5000;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
.ui-dropdown > .dropdown > div > div {
  padding: 6px 12px;
  color: #989898;
  font-size: 12px;
  font-weight: 500i;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
}
.ui-dropdown > .dropdown > div > div:last-child {
  border-bottom: 0;
}
.ui-dropdown > .dropdown > div > div:hover {
  background-color: rgba(30, 177, 250, 0.1);
}
.ui-dropdown > .dropdown > div > div.selected {
  background-color: rgba(30, 177, 250, 0.1);
}
.layout-homepage .homepage-content > div {
  padding-top: 3rem;
}
@media only screen and (max-width: 768px) {
  .layout-homepage .homepage-content > div {
    padding-top: 2rem;
  }
}
.bottom-panel {
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 51000;
  /* navbar is 50000 */
  /* ng-if show animation */
  /* ng-if hide animation */
}
.bottom-panel > .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 1;
}
.bottom-panel > .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  z-index: 2;
}
.bottom-panel > .overlay > .panel {
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  height: 100%;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.bottom-panel > .overlay > .panel > .h-bar {
  width: 30%;
  height: 3px;
  margin: 1rem auto;
  border-radius: 10px;
  background-color: rgba(2, 20, 49, 0.3);
  box-shadow: 1px 1px 1px rgba(2, 20, 49, 0.125);
}
@media only screen and (max-width: 480px) {
  .bottom-panel > .overlay > .panel {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
  }
}
.bottom-panel > .overlay > .panel > div > div {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.bottom-panel.ng-enter {
  animation: slideUp 0.4s ease-out;
  -webkit-animation: slideUp 0.4s ease-out;
}
.bottom-panel.ng-leave {
  animation: slideDown 0.3s linear;
  -webkit-animation: slideDown 0.3s linear;
}
.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: min(38vw, 25rem);
  z-index: 51000;
  /* navbar is 50000 */
  /* ng-if show animation */
  /* ng-if hide animation */
}
.side-panel > .bg {
  display: none;
}
.side-panel > .overlay {
  border-radius: 10px 0 0 10px;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.1);
  border: none;
  z-index: 5001;
  box-sizing: border-box;
}
.side-panel > .overlay > .h-bar {
  display: none;
}
.side-panel > .overlay > .panel {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.side-panel > .overlay > .panel > div {
  height: 100%;
}
.side-panel > .overlay > .panel .panel__content {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.side-panel.ng-enter {
  animation: slideLeft 0.4s ease-out;
  -webkit-animation: slideLeft 0.4s ease-out;
}
.side-panel.ng-leave {
  animation: slideRight 0.3s linear;
  -webkit-animation: slideRight 0.3s linear;
}
/* 
	Keyframes
*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slideUp {
  from {
    transform: translateY(700px);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideUp {
  from {
    transform: translateY(700px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(700px);
  }
}
@-webkit-keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(700px);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(min(38vw, 25rem));
  }
  to {
    transform: translateX(0);
  }
}
@-webkit-keyframes slideLeft {
  from {
    transform: translateX(min(38vw, 25rem));
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(min(38vw, 25rem));
  }
}
@-webkit-keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(min(38vw, 25rem));
  }
}
.flex-container {
  display: flex;
  align-items: center;
}
.align-start {
  align-items: start;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.overflow-x {
  overflow-x: auto;
}
.overflow-y {
  overflow-y: auto;
}
.text-center {
  text-align: center;
}
.text-italic {
  font-style: italic;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.relative-block {
  display: block;
  position: relative;
}
.mt-xs {
  margin-top: .5rem;
}
.mb-xs {
  margin-bottom: .5rem;
}
.mt-small {
  margin-top: 1rem;
}
.mb-small {
  margin-bottom: 1rem;
}
.mr-small {
  margin-right: 1rem;
}
.mr-medium {
  margin-right: 2rem;
}
.ml-medium {
  margin-left: 2rem;
}
.mt-medium {
  margin-top: 2rem;
}
.mb-medium {
  margin-bottom: 2rem;
}
.mt-large {
  margin-top: 4rem;
}
.mb-large {
  margin-bottom: 4rem;
}
.pt-small {
  padding-top: 1rem;
}
.pb-small {
  padding-bottom: 1rem;
}
.pr-small {
  padding-right: 1rem;
}
.pr-medium {
  padding-right: 2rem;
}
.pl-medium {
  padding-left: 2rem;
}
.pt-medium {
  padding-top: 2rem;
}
.pb-medium {
  padding-bottom: 2rem;
}
.pt-large {
  padding-top: 4rem;
}
.pb-large {
  padding-bottom: 4rem;
}
.highlight {
  color: #00A7FF;
}
/* Components */
.ui-account {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 1.875rem;
}
@media only screen and (max-width: 768px) {
  .ui-account {
    padding: 1rem;
  }
}
.ui-account__title {
  text-align: center;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 768px) {
  .ui-account__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}
[ui-your-giftcards] {
  position: relative;
}
.tab-box {
  margin: 10px 0;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdddf;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.24);
}
.tab-box > .tabs {
  display: table;
  width: 100%;
  background-color: #D8D8D8;
  border-radius: 10px 10px 0 0;
}
.tab-box > .tabs > div {
  display: table-cell;
  border-radius: 10px 10px 0 0;
  text-align: center;
  width: 50%;
  padding: 10px 10px;
  background-color: #D8D8D8;
  color: #595959;
  font-size: 13px;
  font-weight: 700;
}
.tab-box > .tabs > div.selected {
  background-color: #ffffff;
  color: #021431;
}
.tab-box .body {
  padding-bottom: 10px;
}
.tab-box .body .item {
  border: solid #e1e1e1;
  border-width: 1px 1px 0 1px;
}
.tab-box .body .item.even {
  background: #f8f9fb;
}
.tab-box .body .item.last {
  border-width: 1px;
}
.tab-box .body .item .separator {
  width: 90%;
  height: 1px;
  background: #e1e8eb;
  margin: 3px auto;
}
.tab-box .body .empty {
  padding: 2rem 0;
  color: #4f566b;
  text-align: center;
}
.tab-box .body .pagination-container {
  width: 100%;
  position: sticky;
  bottom: -2px;
  left: 0;
  margin-top: 46px;
}
.tab-box .body .pagination-container > div {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
}
.tab-box .body .pagination-container > div > div {
  width: 100%;
  position: relative;
  display: inline-block;
  transform: translateY(-100%);
}
.tab-box table.my-giftcards {
  border-spacing: 0;
}
.tab-box .fixed-floating-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffcc00;
  text-align: center;
  z-index: 5000;
}
.tab-box .fixed-floating-bottom > div {
  position: relative;
  display: inline-block;
  transform: translateY(-100%);
}
.tab-box .ui-pagination {
  background: #d3d9e7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  border: 1px solid #5069a0;
  border-width: 0 1px;
}
.tab-box .ui-pagination .pagination {
  display: inline-block;
  margin: 2px 0;
  background: #d3d9e7;
  border: 2px solid #5069a0;
  border-radius: 10px;
}
.tab-box .ui-pagination > div {
  display: table-cell;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}
.details-form {
  padding: 2rem 2rem 0;
}
.details-form > div {
  margin-bottom: 15px;
}
.details-form > div > div {
  margin-bottom: 5px;
}
.details-form > div > div:first-child {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Karla', sans-serif;
  color: rgba(2, 20, 49, 0.5);
}
.details-form > div > div:last-child input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 7px;
  background-color: #ffffff;
  border: 1px solid #dcdddf;
  transition: 0.25s ease-in-out;
}
.details-form > div > div:last-child input:focus {
  outline: none;
  box-shadow: 0 0 0 3px #afc1de;
  border-color: transparent;
}
.details-form > div > div:last-child input[readonly] {
  background-color: #f4f5f7;
  color: #6c748e;
  border-color: transparent;
  pointer-events: none;
}
.details-form > div > div:last-child .action {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  text-decoration: underline;
}
.details-form > div > div:last-child .text-confirm {
  text-align: right;
  font-size: 11px;
  color: #039BE5;
  padding: 5px 0;
}
.details-form > div > div:last-child .text-warning {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
}
.secondary-email-container .read-only {
  border-radius: 5px;
}
.secondary-email-container .read-only div {
  display: flex;
  align-items: center;
  margin: 0 5px;
  opacity: 0.8;
  transition: 0.25s ease-in-out;
}
.secondary-email-container .read-only div:hover {
  opacity: 1;
}
.card {
  background-color: #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid #F3F3F6;
}
.card.--selected {
  border: 3px solid #00A7FF;
}
.card:hover .card__row--cta {
  border-color: #00A7FF;
  background-color: #00A7FF;
}
.card:hover .card__row--cta * {
  color: #FFFFFF;
}
.card:hover .card__row--filtered {
  border-color: #808998;
  background-color: #808998;
}
.card:hover .card__row--filtered * {
  color: #FFFFFF;
}
.card__image-wrapper {
  position: relative;
  margin: 0 auto;
  border-bottom: none;
}
.ui-offer-page .card__image-wrapper {
  border: none;
}
.card__image-wrapper > img {
  width: 100%;
}
.card__image-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  border-radius: 10px;
}
.--selected .card__image-wrapper > div {
  border-radius: 6px 6px 10px 10px;
}
.card__row {
  padding: .625rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .card__row {
    padding: .5rem;
  }
}
.card__row--cta {
  border-top: 1px solid rgba(2, 20, 49, 0.1);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  transition-property: background-color, border;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
}
.--selected .card__row--cta {
  border-radius: 0 0 6px 6px;
}
.card__row--cta > * {
  font-size: .6rem;
}
.card__row--filtered {
  border-color: #E5E7EA;
  background-color: #E5E7EA;
}
.card__row--filtered * {
  color: #737D8E;
}
.card__row + .card__row:not(.card__row--cta) {
  padding-top: 0;
}
.card__row .discount-tag {
  margin-right: -0.625rem;
}
@media only screen and (max-width: 1200px) {
  .card__row .discount-tag {
    margin-right: -0.5rem;
  }
}
.card__row small:first-child {
  text-align: left;
  margin-right: .15rem;
}
.card__name {
  width: 52%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.card__name--full-width {
  width: 100%;
  margin-right: 0;
}
.card__name span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__name span * {
  font-size: inherit;
  color: inherit;
}
.card__icon {
  margin-left: .15rem;
}
.c-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
}
@media only screen and (max-width: 960px) {
  .c-carousel {
    padding: 1.5rem;
  }
}
.c-carousel .slick-track {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.c-carousel__img-wrapper {
  height: 100%;
  outline: none;
}
.c-carousel__image {
  margin: 0 auto 7rem;
}
@media only screen and (max-width: 960px) {
  .c-carousel__image {
    margin-bottom: 4rem;
  }
}
@media only screen and (max-width: 480px) {
  .c-carousel__image {
    margin-bottom: 1rem;
  }
}
.c-carousel__image--full-width {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
}
@media only screen and (max-width: 480px) {
  .c-carousel__image--full-width {
    margin-bottom: 5rem;
  }
}
.c-carousel .slick-slide {
  outline: none;
  will-change: transform;
}
.c-carousel .slick-slide img {
  width: 100%;
  max-width: 230px;
}
.c-carousel .slick-prev {
  display: none !important;
}
.c-carousel .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 10px;
  text-align: center;
}
.c-carousel .slick-dots li button {
  padding: 0 2px;
  background: none;
  font-size: 9px;
  color: #0214311a;
  border: 0;
  outline: none;
}
.c-carousel .slick-dots li button:focus {
  outline: none;
}
.c-carousel .slick-dots li button:hover {
  cursor: pointer;
}
.c-carousel .slick-dots li.slick-active button {
  color: #00a7ff;
}
.slick-controls {
  width: 100%;
  padding: 0 2.5rem 2.5rem;
  box-sizing: border-box;
}
@media only screen and (max-width: 960px) {
  .slick-controls {
    margin: 1rem 0 0;
    padding: 0 1.5rem 1.5rem;
  }
}
.slick-controls button {
  width: 100%;
  padding: 10px;
  background: #00a7ff;
  color: #ffffff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  outline: none;
}
.slick-controls button:hover {
  background: #128ef2;
}
@media only screen and (max-width: 768px) {
  .slick-controls button {
    font-size: 14px;
  }
}
.slick-controls button.secondary {
  background-color: transparent;
  font-size: 14px;
  color: #a2a3a7;
  box-shadow: none;
  display: none;
}
@media only screen and (max-width: 960px) {
  .slick-controls button.secondary {
    margin-top: .5rem;
    display: inline-block;
  }
}
.slick-controls button.secondary:hover {
  color: #808998;
  background: rgba(0, 0, 0, 0.1);
}
/* 
	Default Slick Slider CSS
*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.checkout-page[ng-click] {
  cursor: default;
}
.checkout-page .ui-offer-page .carousel-wrapper {
  width: 100%;
  height: 170px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .carousel-wrapper {
    height: 158px;
  }
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .carousel-wrapper {
    margin-bottom: 1rem;
  }
}
.checkout-page .ui-offer-page .carousel-wrapper--no-thumbs {
  justify-content: center;
}
.checkout-page .ui-offer-page .carousel-wrapper--no-thumbs .checkout-slider.checkout-slider-for {
  margin: auto;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for {
  width: calc((100% - .5rem) - 43px);
  max-width: 270px;
  height: 170px;
  overflow: hidden;
  margin-right: .5rem;
  cursor: default;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-for {
    max-width: 250px;
    height: 158px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__image-wrapper {
  max-width: 270px;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__image-wrapper > div {
  border-radius: 10px;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__image-wrapper {
    max-width: 250px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav {
  width: 43px;
  height: 170px;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav {
    width: 40px;
    height: 158px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__image_repeater {
  border: 1px solid transparent;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__image-wrapper {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid transparent;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__image-wrapper > img {
  display: inline-block;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__image-wrapper {
    width: 33px;
    height: 33px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-slide {
  outline: none;
  cursor: pointer;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-slide.slick-current .card__image-wrapper {
  border: 2px solid #00A7FF;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-track,
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-list {
  height: 170px !important;
}
@media only screen and (max-width: 1024px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-track,
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-list {
    height: 158px !important;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-list,
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-track,
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-slide {
  box-sizing: border-box;
}
.checkout-page .ui-offer-page .checkout__tags {
  height: calc(30px + 1rem);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.checkout-page .ui-offer-page .checkout__tags > * {
  margin-right: .5rem;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout__description {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
.checkout-page .ui-offer-page .checkout__small {
  color: #00A7FF;
  cursor: pointer;
  margin-top: .5rem;
  margin-bottom: 1rem;
}
.checkout-page .ui-offer-page .checkout__subheading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout__subheading {
    margin-top: 1rem;
  }
}
.checkout-page .ui-offer-page .checkout__subheading .discount-tag {
  margin-left: .5rem;
}
.checkout-page .ui-offer-page .ui-form .regular {
  padding: .75rem 0;
  width: inherit;
}
.checkout-page .ui-offer-page .ui-form button {
  width: 100%;
  padding: 11px 0;
  background: #00A7FF;
  color: #FFFFFF;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  outline: none;
  transition: 0.25s ease-in-out;
}
.checkout-page .ui-offer-page .ui-form button:hover {
  background: #128ef2;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .ui-form button {
    font-size: 14px;
  }
}
.checkout-page .ui-offer-page .ui-form button.secondary {
  font-size: 14px;
  background-color: transparent;
  color: #a2a3a7;
  box-shadow: none;
}
.checkout-page .ui-offer-page .ui-form button.secondary:hover {
  color: #808998;
  background: rgba(0, 0, 0, 0.1);
}
.checkout-confirmation .panel__content {
  justify-content: space-between !important;
}
.checkout-confirmation .body {
  width: 100%;
  padding: 2rem 3rem 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .checkout-confirmation .body {
    padding: 0 2rem;
    margin-bottom: 7rem;
  }
}
.checkout-confirmation .body img {
  width: 100%;
  max-width: 75%;
  display: block;
}
@media only screen and (max-width: 480px) {
  .checkout-confirmation .body img {
    margin-bottom: 2rem;
  }
}
.checkout-confirmation .ui-form {
  border-top: none;
}
.discount-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: #0B43A0;
  color: #FFFFFF;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  height: 30px;
}
.discount-tag.--small {
  height: 20px;
}
.discount-tag:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  border: 10px solid #0B43A0;
  border-left-width: 9px;
  border-bottom-width: 10px;
  border-right-width: 2px;
  border-top-width: 10px;
  z-index: 1;
  left: -10px;
  border-left-color: transparent;
}
.discount-tag.--pill {
  border-radius: 25px;
  padding: 8px 16px;
}
.discount-tag.--pill:before {
  border: 0;
}
.discount-tag.--inline {
  display: inline-flex;
  vertical-align: middle;
}
.discount-tag > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.discount-tag__image {
  max-width: 1.125rem;
  margin-right: .675rem;
}
.discount-tag__image.--small {
  max-width: .875rem;
  margin-right: .3125rem;
}
.discount-tag__meta {
  color: #FFFFFF;
  line-height: unset;
}
.hero {
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  background-color: transparent;
}
@media only screen and (max-width: 640px) {
  .hero {
    height: 150px;
  }
}
.hero > div {
  display: flex;
  height: 280px;
}
@media only screen and (max-width: 640px) {
  .hero > div {
    height: 150px;
  }
}
.hero__content {
  background-color: #FFFFFF;
  border-radius: 180px 10px 10px 180px;
  height: 100%;
  width: 70%;
  margin: 0 0 0 auto;
  box-sizing: border-box;
}
@media only screen and (min-width: 1600px) {
  .hero__content {
    width: 60%;
  }
}
@media only screen and (max-width: 1200px) {
  .hero__content {
    width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .hero__content {
    width: 85%;
  }
}
@media only screen and (max-width: 960px) {
  .hero__content {
    width: 80%;
  }
}
@media only screen and (max-width: 640px) {
  .hero__content {
    width: 70%;
    border-radius: 130px 9px 9px 130px;
  }
}
@media only screen and (max-width: 375px) {
  .hero__content {
    width: 78%;
  }
}
.hero__inner {
  padding: 2rem 2rem 2rem 5rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 640px) {
  .hero__inner {
    padding: 2rem 1rem 2rem 4rem;
  }
}
@media only screen and (max-width: 480px) {
  .hero__inner {
    padding: 1rem 1rem 1rem 2.5rem;
  }
}
.hero__logo {
  height: 100%;
  max-height: 25px;
  width: auto;
  max-width: 100%;
}
@media only screen and (max-width: 640px) {
  .hero__logo {
    margin-bottom: .5rem;
    max-height: 20px;
  }
}
.hero__logo img {
  width: auto;
  height: 100%;
  display: inline-block !important;
}
@media only screen and (max-width: 640px) {
  .hero__heading {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .hero__heading {
    font-size: 1rem;
  }
}
.hero__subheading {
  line-height: 1.3;
}
@media only screen and (max-width: 640px) {
  .hero__subheading {
    display: none;
  }
}
.hero__button {
  width: unset;
  padding: .6rem .9rem;
  font-size: .75rem;
  background: #00A7FF;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}
.hero__button:hover {
  background: #128ef2;
}
@media only screen and (max-width: 640px) {
  .hero__button {
    padding: .5rem 1rem;
    font-size: .675rem;
  }
}
@media only screen and (max-width: 480px) {
  .hero__button {
    padding: .4rem 1rem;
    font-size: .625rem;
  }
}
.hero__thumbnail {
  height: 25% !important;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: .5rem 1rem;
  overflow: hidden;
}
.hero__thumbnail:not(:last-child) {
  margin-bottom: .5rem;
}
.hero__thumbnail img {
  max-width: 100%;
  max-height: 100%;
}
.hero .hero-slider {
  cursor: pointer;
}
.hero .hero-slider-for {
  width: calc(calc(100% - 175px) - 4px - .5rem);
  max-width: 1322px;
  height: 280px;
  overflow: hidden;
  margin-right: .5rem;
}
@media only screen and (max-width: 1200px) and (min-width: 961px) {
  .hero .hero-slider-for {
    width: calc(calc(100% - 100px) - 4px - .5rem);
  }
}
@media only screen and (max-width: 640px) {
  .hero .hero-slider-for {
    width: 100%;
    margin-right: 0;
    height: 150px;
  }
}
.hero .hero-slider-for.--no-thumbs {
  width: 100%;
  margin-right: 0;
}
.hero .hero-slider-for .slick-slide {
  height: 280px;
}
@media only screen and (max-width: 640px) {
  .hero .hero-slider-for .slick-slide {
    height: 150px;
    width: 100% !important;
    max-width: min(72vw, 580px) !important;
    margin-right: 1rem;
    will-change: transform;
  }
}
@media only screen and (max-width: 480px) {
  .hero .hero-slider-for .slick-slide {
    width: 100% !important;
    max-width: min(78vw, 350px) !important;
    margin-right: 1rem;
  }
}
.hero .hero-slider-for .slick-list {
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border-radius: 0;
}
.hero .hero-slider-for .slick-track {
  width: 100%;
  box-sizing: border-box;
}
.hero .hero-slider-for .slick-track > div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  outline: none;
  border-radius: 10px;
}
.hero .hero-slider-nav {
  width: calc(175px + 4px + .5rem);
  height: 280px;
}
@media only screen and (max-width: 1200px) and (min-width: 961px) {
  .hero .hero-slider-nav {
    width: calc(100px + 4px + .5rem);
  }
}
@media only screen and (max-width: 640px) {
  .hero .hero-slider-nav {
    display: none;
  }
}
.hero .hero-slider-nav.--no-thumbs {
  display: none;
}
.hero .hero-slider-nav .slick-track,
.hero .hero-slider-nav .slick-list {
  height: 280px !important;
}
.hero .hero-slider-nav .slick-list,
.hero .hero-slider-nav .slick-track,
.hero .hero-slider-nav .slick-slide {
  box-sizing: border-box;
}
.hero .hero-slider-nav .slick-slide {
  outline: none;
  border: 2px solid transparent;
}
.hero .hero-slider-nav .slick-slide.slick-current {
  border-color: #00A7FF;
}
.message {
  text-align: center;
  margin-bottom: 3.5rem;
}
.message__header {
  margin-bottom: 0.75rem;
  color: #021431;
  font-size: 40px;
  font-weight: 500;
}
.message__subheader {
  color: #02143180;
  font-size: 18px;
  font-weight: 500;
}
.message__title,
.message__heading {
  margin-bottom: .5rem;
}
.vertical-offers__heading {
  margin-bottom: 1.5rem;
}
.vertical-offers .offers {
  display: grid;
  gap: 2rem;
  -ms-grid-column: 4;
  -ms-grid-row: auto;
}
@media only screen and (min-width: 1600px) {
  .vertical-offers .offers {
    grid-template-columns: repeat(auto-fill, minmax(min(185px, 10vw), 1fr)) !important;
    gap: 3.5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .vertical-offers .offers {
    gap: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .vertical-offers .offers {
    grid-template-columns: repeat(auto-fill, minmax(min(185px, 28.25vw), 1fr)) !important;
  }
}
@media only screen and (max-width: 640px) {
  .vertical-offers .offers {
    grid-template-columns: repeat(auto-fill, minmax(min(185px, 29vw), 1fr)) !important;
    gap: 1rem;
  }
}
@media screen only and (max-width: 374px) {
  .vertical-offers .offers {
    grid-template-columns: auto !important;
  }
}
.horizontal-offers {
  background-color: #F3F3F6;
}
.horizontal-offers__heading {
  margin-bottom: 1.5rem;
}
.horizontal-offers .offers {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  /*
		// TODO: add transparency to edge and update arrow style
		&::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 94px;
			height: 100%;
			background: transparent linear-gradient(271deg, rgba(235, 235, 235, 1) 0%, rgba(235, 235, 235, 0) 100%) 0% 0% no-repeat padding-box;
			right: 0;
			left: unset;
		}
		*/
  /* slick slider */
}
.horizontal-offers .offers--active-prev .offers__overlay--next.slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.horizontal-offers .offers--active-next .offers__overlay--prev.slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.horizontal-offers .offers__overlay {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width: 480px) {
  .horizontal-offers .offers__overlay {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
.horizontal-offers .offers__overlay.slick-arrow.slick-disabled {
  opacity: 0;
  visibility: hidden;
}
.horizontal-offers .offers__overlay--prev {
  left: calc(.25rem + 1rem);
}
.horizontal-offers .offers__overlay--next {
  right: 1rem;
  left: unset;
}
.horizontal-offers .offers__table {
  width: 100%;
  margin: 0 -0.5rem;
}
@media only screen and (max-width: 640px) {
  .horizontal-offers .offers__table {
    margin: 0;
  }
}
.horizontal-offers .offers__cell {
  box-sizing: border-box;
  margin: 0 .5rem;
  min-width: 185px;
}
@media only screen and (max-width: 640px) {
  .horizontal-offers .offers__cell {
    margin: 0 1rem 0 0;
  }
}
.horizontal-offers .offers__cell:focus {
  outline: none;
}
.horizontal-offers .offers .horizontal-slider .slick-list {
  padding: 0 2rem 0 0;
}
@media only screen and (min-width: 1600px) {
  .horizontal-offers .offers .horizontal-slider .slick-list {
    padding: 0;
  }
}
@media only screen and (max-width: 480px) {
  .horizontal-offers .offers .horizontal-slider .slick-list {
    padding: 0;
  }
}
.progress-bar {
  border-radius: 5px;
  width: 80%;
  height: 20px;
  margin: 10px auto;
  border: 1px solid #5069A0;
  background-color: #E9E9E9;
  display: table;
}
.progress-bar > .progress {
  display: table-cell;
  height: 20px;
  border-radius: 5px;
  background-color: #1EB1FA;
  min-width: 5px;
}
.progress-bar > .progress.with-pending {
  border-radius: 5px 0 0 5px;
}
.progress-bar > .pending {
  display: table-cell;
  background-image: linear-gradient(45deg, #1eb1fa 25%, #e9e9e9 25%, #e9e9e9 50%, #1eb1fa 50%, #1eb1fa 75%, #e9e9e9 75%, #e9e9e9 100%);
  background-size: 10px 10px;
  height: 20px;
  border-radius: 0 5px 5px 0;
}
.progress-bar > .error {
  display: table-cell;
  background-color: #D50000;
  height: 20px;
  border-radius: 0 5px 5px 0;
}
.giftcard-preview {
  padding: 40px 30px;
  text-align: center;
}
.giftcard-preview > div {
  display: inline-block;
  width: 225px;
  height: 120px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.giftcard-preview > div > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.giftcard-preview > div > .info {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.giftcard-preview > div > .info > .name {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
}
.giftcard-preview > div > .info > .gc {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.ui-offer-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
}
.ui-offer-page .merchant-content-wrapper {
  position: relative;
  padding: 2.5rem 2.5rem 1.5rem;
}
@media only screen and (max-width: 1024px) {
  .ui-offer-page .merchant-content-wrapper {
    padding: 1.5rem;
  }
}
@media only screen and (max-width: 960px) {
  .ui-offer-page .merchant-content-wrapper {
    padding: 0 1.5rem 1rem;
  }
}
.ui-offer-page .merchant-content-wrapper .gift-card-container {
  width: 240px;
  height: 100%;
  position: relative;
  background-position: top center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding: 7px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 10px auto;
  color: #ffffff;
  font-weight: 500;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info .card-title {
  width: 60%;
  height: 41px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info .card-value {
  font-size: 18px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-wrapper {
  width: 165px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 13px;
}
.ui-offer-page .merchant-content-wrapper .gift-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 30px;
  height: 104px;
  width: 165px;
  display: table;
  margin: 0 auto 10px auto;
  left: 50%;
  border-radius: 13px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ui-offer-page .merchant-content-wrapper .gift-card > div {
  display: table-cell;
  padding: 15px 0;
  vertical-align: middle;
  text-align: center;
  border-radius: 13px;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .card-value {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 7px;
  font-size: 24px;
  font-weight: 700;
  color: #1eb1fa;
  text-align: center;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .sep {
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  margin: 7px auto;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .text {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 10px 35px;
  line-height: 20px;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .more {
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.ui-offer-page .description-container {
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  overflow: hidden;
}
.ui-offer-page .description-container .merchant-description {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  height: 28px;
  text-align: left;
  overflow: hidden;
}
.ui-offer-page .description-container .merchant-description a {
  color: #ffffff;
}
.ui-offer-page .description-container .merchant-description a:hover {
  text-decoration: underline;
}
.ui-offer-page .description-container .merchant-description.show {
  overflow: visible;
  height: auto;
}
.ui-offer-page .description-container .read-more,
.ui-offer-page .description-container .read-less {
  margin: 3px 0;
  font-size: 12px;
}
.ui-offer-page .description-container .read-more:hover,
.ui-offer-page .description-container .read-less:hover {
  text-decoration: underline;
}
.ui-offer-widget-B {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
}
.ui-offer-widget-B > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.ui-offer-widget-B > .name {
  position: absolute;
  top: 17px;
  left: 17px;
}
.ui-offer-widget-B > .name > .merchant {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}
.ui-offer-widget-B > .name > .gc {
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
}
.ui-offer-widget-B > .info {
  position: absolute;
  bottom: 17px;
  left: 17px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #f3941d;
  border-radius: 3px;
  padding: 3px 10px;
}
.ui-offer-widget {
  text-align: center;
  /* ng-if show animation */
  /* ng-if hide animation */
}
.ui-offer-widget.ng-enter {
  transition: 0.3s ease-out all;
  opacity: 0;
}
.ui-offer-widget.ng-enter.ng-enter-active {
  opacity: 1;
}
.ui-offer-widget.ng-leave {
  transition: 0.3s ease-out all;
  opacity: 1;
}
.ui-offer-widget.ng-leave.ng-leave-active {
  opacity: 0;
}
.powered-title {
  background-color: #fdfdff;
  padding: 14px;
  text-align: center;
}
.powered-title.shadow {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.powered-title > div {
  display: inline-block;
  margin: 0 auto;
}
.powered-title > div > .text {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: rgba(64, 64, 64, 0.9);
  text-shadow: 0 1px 2px #d6d6d6;
}
.powered-title > div > .powered {
  font-size: 7px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(112, 112, 112, 0.9);
  text-align: right;
  vertical-align: middle;
}
.powered-title > div > .powered > img {
  margin-left: 4px;
  vertical-align: middle;
}
.cashout_confirmation {
  background-color: #0F567A;
  padding: 15px;
}
.cashout_confirmation > .title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}
.cashout_confirmation > .text {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.cashout_confirmation > .debug {
  font-size: 9px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 10px 0;
}
.invalid-country > div {
  text-align: center;
  padding: 30px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #0F79AF;
}
.sticky.side-panel-layout {
  padding-top: 0;
  transition: 0.3s all ease-out;
}
@media only screen and (max-width: 480px) {
  .sticky.side-panel-layout.--isSticky {
    padding-top: 1rem;
  }
}
.sticky__component {
  top: 0;
  position: sticky;
  background-color: #F3F3F6;
  z-index: 2;
  border-bottom: 2px solid transparent;
  transition: 0.3s top ease-out;
}
.--isSticky .sticky__component {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: -3rem;
  margin-right: -3rem;
  padding-top: 1rem;
  border-color: rgba(2, 20, 49, 0.1);
}
@media only screen and (min-width: 1600px) {
  .--isSticky .sticky__component {
    padding-left: 9rem;
    padding-right: 9rem;
    margin-left: -9rem;
    margin-right: -9rem;
  }
}
@media only screen and (max-width: 1440px) {
  .--isSticky .sticky__component {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .--isSticky .sticky__component {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .--isSticky .sticky__component {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media only screen and (max-width: 480px) {
  .--isSticky .sticky__component {
    top: -3rem;
  }
}
.--isSticky .sticky__component > div {
  padding: 0;
}
.sticky__slider {
  padding-bottom: 1rem;
}
.value-slider {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /*
		https://github.com/angular-slider/angularjs-slider
		slider overrides
	*/
}
@media only screen and (max-width: 1200px) {
  .value-slider {
    flex-flow: column;
    align-items: stretch;
  }
}
@media only screen and (max-width: 960px) {
  .value-slider {
    flex-flow: row nowrap;
  }
}
@media only screen and (max-width: 640px) {
  .value-slider {
    flex-flow: column;
    align-items: stretch;
  }
}
.value-slider__message {
  margin-right: 2rem;
}
@media only screen and (min-width: 1441px) {
  .value-slider__message {
    margin-right: 4rem;
  }
}
@media only screen and (min-width: 1600px) {
  .value-slider__message {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .value-slider__message {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 960px) {
  .value-slider__message {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 640px) {
  .value-slider__message {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.value-slider__object {
  flex: 1;
  max-width: 650px;
}
.value-slider .rzslider .rz-bar-wrapper {
  max-width: 100%;
}
.value-slider .rzslider .rz-bar {
  background-color: #808998;
  height: 5px;
}
.value-slider .rzslider .rz-selection {
  background-color: #00A7FF;
}
.value-slider .rzslider .rz-tick {
  width: 0;
  height: 0;
}
.value-slider .rzslider .rz-tick-value {
  color: #808998;
  font-size: .75rem;
  top: -24px;
  margin-left: -4px;
}
@media only screen and (min-width: 1441px) {
  .value-slider .rzslider .rz-tick-value {
    font-size: .85rem;
    top: -26px;
  }
}
@media only screen and (min-width: 1600px) {
  .value-slider .rzslider .rz-tick-value {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 480px) {
  .value-slider .rzslider .rz-tick-value {
    font-size: .675rem;
  }
}
.value-slider .rzslider .rz-pointer {
  width: 18px;
  height: 18px;
  top: -7px;
  margin-left: 0;
  background-color: #00A7FF;
  box-shadow: 0 0 0 0 #00a7ff;
}
@media only screen and (max-width: 480px) {
  .value-slider .rzslider .rz-pointer {
    width: 24px;
    height: 24px;
    top: -10px;
    margin-left: -2px;
  }
}
.value-slider .rzslider .rz-pointer::after {
  top: 1.65px;
  left: 6.5px;
  border-radius: unset;
  width: 0;
  height: 0;
  content: '\f7a5';
  font-family: 'Font Awesome 5 Pro';
  color: #FFFFFF;
  opacity: .75;
  font-size: 11px;
  font-weight: 400;
}
@media only screen and (max-width: 480px) {
  .value-slider .rzslider .rz-pointer::after {
    top: 5px;
    left: 9px;
  }
}
.value-slider .rzslider .rz-pointer:focus,
.value-slider .rzslider .rz-pointer.rz-active {
  box-shadow: 0px 0px 0px 2px #83CEFB;
  outline: none;
}
.value-slider .rzslider .rz-bubble {
  visibility: hidden !important;
  display: none !important;
}
.value-slider .rzslider .rz-selected:last-child .rz-tick-value,
.value-slider .rzslider .rz-selected.--selected .rz-tick-value {
  color: #00A7FF;
  font-weight: 600;
}
/*
	RZSlider default css
*/
/*! angularjs-slider - v7.0.0 - 
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervi.eu>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> - 
 https://github.com/angular-slider/angularjs-slider - 
 2019-02-23 */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  user-select: none;
}
.rzslider.noanimate * {
  transition: none !important;
}
.rzslider.with-legend {
  margin-bottom: 40px;
}
.rzslider[disabled] {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}
.rzslider[disabled] .rz-draggable {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-selection {
  background: #8b91a2;
}
.rzslider[disabled] .rz-tick {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-tick.rz-selected {
  background: #8b91a2;
}
.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}
.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
  transition: all linear 0.3s;
}
.rzslider .rz-draggable {
  cursor: move;
}
.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rzslider .rz-bar-wrapper.rz-transparent .rz-bar {
  background: transparent;
}
.rzslider .rz-bar-wrapper.rz-left-out-selection .rz-bar {
  background: #df002d;
}
.rzslider .rz-bar-wrapper.rz-right-out-selection .rz-bar {
  background: #03a688;
}
.rzslider .rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  transition: background-color linear 0.3s;
}
.rzslider .rz-restricted {
  z-index: 3;
  background: #ff0000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  transition: all linear 0.3s;
}
.rzslider .rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  content: '';
}
.rzslider .rz-pointer:hover:after {
  background-color: #ffffff;
}
.rzslider .rz-pointer.rz-active {
  z-index: 4;
}
.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff;
}
.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default;
  transition: all linear 0.3s;
}
.rzslider .rz-bubble.rz-limit {
  color: #55637d;
  transition: none;
}
.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
.rzslider .rz-ticks-values-under .rz-tick-value {
  top: auto;
  bottom: -32px;
}
.rzslider .rz-tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-left: 11px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%;
  transition: background-color linear 0.3s;
}
.rzslider .rz-tick.rz-selected {
  background: #0db9f0;
}
.rzslider .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}
.rzslider .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
}
.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}
.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}
.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}
.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}
.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}
.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
}
.rzslider.rz-vertical .rz-tick {
  margin-top: 11px;
  margin-left: auto;
  vertical-align: middle;
}
.rzslider.rz-vertical .rz-tick-value {
  top: auto;
  left: 24px;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-tick-legend {
  top: auto;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-ticks-values-under .rz-tick-value {
  right: 24px;
  bottom: auto;
  left: auto;
}
.loading .overlay > div {
  padding: 4rem 3rem 2rem;
  text-align: center;
  border-radius: 10px;
}
.loading .loading-wrapper {
  height: 375px;
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .loading .loading-wrapper {
    width: 330px;
  }
}
.loading .loading-wrapper .logo {
  width: 100%;
  max-width: 135px;
  height: auto;
  position: relative;
  z-index: 2;
}
.loading .loading-wrapper .confetti {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  animation: propagateOut 0.8s ease-in-out 0s alternate infinite none running;
}
@keyframes propagateOut {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    top: -55%;
    transform: scale(1.6);
  }
}
