.message {
    text-align:     center;
    margin-bottom:  3.5rem;
   
    &__header {
        margin-bottom:  0.75rem;
        color:          #021431;
        font-size:      40px;
        font-weight:    500;
    }

    &__subheader {
        color:          #02143180;
        font-size:      18px;
        font-weight:    500;
    }

    &__title,
    &__heading {
        margin-bottom: .5rem;
    }
}
