.loading {
	.overlay {
		& > div {
			padding:		4rem 3rem 2rem;
			text-align:		center;
			border-radius:	@default-border-radius;
		}
	}

	.loading-wrapper {
		height:					375px;
		width:					390px;
		display:				flex;
		flex-direction:			column;
		align-items:			center;
		justify-content:		center;
		background:				@white;
		box-sizing:				border-box;

		@media @mobile-portrait-down {
			width:				330px;
		}
		
		.logo {
			width: 				100%;
			max-width: 			135px;
			height:				auto;
			position: 			relative;
			z-index: 			2;
		}
		.confetti {
			width:				auto;
			height:				auto;
			position:			absolute;
			top:				0;
			left:				0;
			z-index:			1;
			animation: 			propagateOut
								.8s
								ease-in-out
								0s
								alternate
								infinite
								none
								running;
		}
	}
}
@keyframes propagateOut {
	0% {
		opacity:	0;
		transform:	scale(0);
	}
	100% {
		opacity:	1;
		top:		-55%;
		transform:	scale(1.6);
	}
}
