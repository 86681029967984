.hero {
	@heroHeight:				280px;
	@heroHeightMobile:			150px;
	@thumbnailsWidth: 			175px;
	@thumbnailsSmallWidth: 		100px;
	@previewWidth: 				~'calc(100% - @{thumbnailsWidth})';
	@previewMaxWidth: 			1322px;
	@previewSmallWidth: 		~'calc(100% - @{thumbnailsSmallWidth})';
	@previewMobileLandscapeMaxWidth: 	~'min(72vw, 580px)';
	@previewMobilePortraitMaxWidth: 	~'min(78vw, 350px)';

	width:				100%;
	height:				@heroHeight;
	box-sizing:			border-box;
	background-color:	transparent;

	@media @mobile-landscape-down {
		height:	@heroHeightMobile;
	}

	& > div {
		display:	flex;
		height:		@heroHeight;

		@media @mobile-landscape-down {
			height:	@heroHeightMobile;
		}
	}

	&__content {
		background-color: 	@white;
		border-radius: 		180px 10px 10px 180px;
		height: 			100%;
		width: 				70%;
		margin: 			0 0 0 auto;
		box-sizing: 		border-box;

		@media @monitor-up {
			width: 60%;
		}

		@media @laptop-down {
			width: 80%;
		}

		@media @tablet-landscape-down {
			width: 85%;
		}

		@media @split-screen-down {
			width: 80%;
		}

		@media @mobile-landscape-down {
			width: 70%;
			border-radius: 130px 9px 9px 130px;
		}

		@media @tiny-mobile-down {
			width: 78%;
		}
	}

	&__inner {
		padding: 			2rem 2rem 2rem 5rem;
		width: 				100%;
		height: 			100%;
		box-sizing: 		border-box;
		display: 			flex;
		flex-flow: 			column;
		align-items: 		flex-start;
		justify-content: 	center;
		text-overflow: 		ellipsis;
		overflow: 			hidden;

		@media @mobile-landscape-down {
			padding: 2rem 1rem 2rem 4rem;
		}

		@media @mobile-portrait-down {
			padding: 1rem 1rem 1rem 2.5rem;
		}
	}

	&__logo {
		height: 	100%;
		max-height:	25px;
		width:		auto;
		max-width:  100%;

		@media @mobile-landscape-down {
			margin-bottom:	.5rem;
			max-height:		20px;
		}

		img {
			width: 		auto;
			height: 	100%;
			display:	inline-block !important;
		}
	}

	&__heading {
		@media @mobile-landscape-down {
			font-size: 1.1rem;
			margin-bottom: 1rem;
		}

		@media @mobile-portrait-down {
			font-size: 1rem;
		}
	}

	&__subheading {
		line-height: 1.3;

		@media @mobile-landscape-down {
			display: none;
		}
	}

	&__button {
		width:			unset;
		padding: 		.6rem .9rem;
		font-size: 		.75rem;
		background:		@blue-highlight;
		color:			@white;
		border:			none;
		border-radius:	@alternate-border-radius;
		outline:		none;
		transition:		0.25s ease-in-out;
		cursor: 		pointer;

		&:hover {
			background:	#128ef2;
		}

		@media @mobile-landscape-down {
			padding: 	.5rem 1rem;
			font-size: 	.675rem;
		}

		@media @mobile-portrait-down {
			padding: 	.4rem 1rem;
			font-size: 	.625rem;
		}
	}

	&__thumbnail {
		height:				25% !important;
		background-color:	@white;
		border-radius:		@default-border-radius;
		display:			flex !important;
		align-items:		center;
		justify-content:	center;
		padding:			.5rem 1rem;
		overflow:			hidden;

		&:not(:last-child) {
			margin-bottom:	.5rem;
		}

		img {
			max-width:	100%;
			max-height:	100%;
		}
	}

	.hero-slider {
		cursor: pointer;
	}

	// preview
    .hero-slider-for {
		width:			~'calc(@{previewWidth} - 4px - .5rem)';
		max-width: 		@previewMaxWidth;
		height:			@heroHeight;
		overflow: 		hidden;
		margin-right:	.5rem;

		@media only screen and (max-width: 1200px) and (min-width: 961px) {
			width:	~'calc(@{previewSmallWidth} - 4px - .5rem)';
		}

		@media @mobile-landscape-down {
			width:			100%;
			margin-right:	0;
			height:			@heroHeightMobile;
		}

		&.--no-thumbs {
			width: 100%;
			margin-right: 0;
		}

		.slick-slide {
			height:		@heroHeight;

			@media @mobile-landscape-down {
				height:			@heroHeightMobile;
				width: 			100% !important;
				max-width: 		@previewMobileLandscapeMaxWidth !important;
				margin-right: 	1rem;
				will-change: 	transform;
			}

			@media @mobile-portrait-down {
				width: 			100% !important;
				max-width: 		@previewMobilePortraitMaxWidth !important;
				margin-right: 	1rem;
			}
		}

		.slick-list {
			height:			100%;
			box-sizing: 	border-box;
			border-radius:	@default-border-radius;
			border-radius:	0;
		}

		.slick-track {
			width:		100%;
			box-sizing: border-box;
			& > div {
				background-repeat:		no-repeat;
				background-size: 		cover;
				background-position:	center left;
				outline:				none;
				border-radius: 			@default-border-radius;
			}
		}
    }

    // thumbnails
    .hero-slider-nav {
		width:		~'calc(@{thumbnailsWidth} + 4px + .5rem)';
		height:		@heroHeight;

		@media only screen and (max-width: 1200px) and (min-width: 961px) {
			width:	~'calc(@{thumbnailsSmallWidth} + 4px + .5rem)';
		}

		@media @mobile-landscape-down {
			display: none;
		}

		&.--no-thumbs {
			display: none;
		}

		.slick-track,
		.slick-list {
			height:	@heroHeight !important;
		}

		.slick-list,
		.slick-track,
		.slick-slide {
			box-sizing: border-box;
		}

        .slick-slide {
			outline:        none;
			border:			2px solid transparent;

            &.slick-current {
				border-color:	@blue-highlight;
            }
		}
    }
}
