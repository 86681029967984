.ui-account {
	width: 			100%;
	margin: 		0 auto;
	max-width: 		1440px;
	box-sizing: 	border-box;
	padding: 		1.875rem;

	@media only screen and (max-width: 768px) {
		padding: 	1rem;
	}

	&__title {
		text-align: 	center;
		margin-bottom: 	3rem;

		@media only screen and (max-width: 768px) {
			font-size: 		1.5rem;
			margin-bottom: 	2rem;
		}
	}
}
[ui-your-giftcards] {
	position:	relative;
}

.tab-box {
	@border-radius:		10px;
	margin:				10px 0;
	border-radius:		@border-radius;
	background-color:	#ffffff;
	border:				1px solid #dcdddf;
	box-shadow:			2px 2px 14px rgba(0, 0, 0, 0.24);
	& > .tabs {
		display:			table;
		width:				100%;
		background-color:	#D8D8D8;
		border-radius:		@border-radius @border-radius 0 0;
		& > div {
			display:			table-cell;
			border-radius:		@border-radius @border-radius 0 0;
			text-align:			center;
			width:				50%;
			padding:			10px 10px;
			background-color:	#D8D8D8;
			color:				#595959;
			font-size:			13px;
			font-weight:		700;
			&.selected {
				background-color:	#ffffff;
				color:				#021431;
			}
		}
	}
	.body {
		padding-bottom:	10px;
		.item {
			border:				solid #e1e1e1;
			border-width:		1px 1px 0 1px;
			&.even {
				background:	#f8f9fb;
			}
			&.last {
				border-width:	1px;
			}
			.separator {
				width: 		90%;
				height: 	1px;
				background: #e1e8eb;
				margin: 	3px auto;
			}
		}
		.empty {
			padding:		2rem 0;
			color:			#4f566b;
			text-align:		center;
		}
		.pagination-container {
			width:			100%;
			position:		sticky;
			bottom:			-2px;
			left:			0;
			margin-top:		46px;
			& > div {
				width:		100%;
				height:		2px;
				position:	absolute;
				bottom:		0;
				left:		0;
				text-align:	center;
				& > div {
					width:		100%;
					position:	relative;
					display:	inline-block;
					transform: 	translateY(-100%);
				}
			}
		}
	}
	table {
		&.my-giftcards {
			border-spacing:		0;
		}
	}
	.fixed-floating-bottom {
		position: 			absolute;
		bottom: 			0;
		left:				0;
		width: 				100%;
		height: 			2px;
		background-color: 	#ffcc00;
		text-align: 		center;
		z-index: 			5000;
		& > div {
			position: 		relative;
			display: 		inline-block;
			transform: 		translateY(-100%);
		}
	}
	.ui-pagination {
		background:		#d3d9e7;
		box-shadow:		0 0 5px rgba(0, 0, 0, 0.6);
		border:			1px solid #5069a0;
		border-width:	0 1px;
		.pagination {
			display:		inline-block;
			margin:			2px 0;
			background:		#d3d9e7;
			border:			2px solid #5069a0;
			border-radius:	10px;
		}
		& > div {
			display:		table-cell;
			padding:		3px 6px;
			font-size:		11px;
			font-weight:	500;
			color:			#333333;
		}
	}
}

.details-form {
	padding:	2rem 2rem 0;
	& > div {
		margin-bottom:		15px;
		& > div {
			margin-bottom:	5px;
			&:first-child {
				font-size:		14px;
				font-weight:	400;
				font-family: 	'Karla', sans-serif;
				color: 			rgba(2, 20, 49, .5);
			}
			&:last-child {
				input {
					width:				100%;
					box-sizing:			border-box;
					border-radius:		5px;
					padding:			7px;
					background-color:	#ffffff;
					border:				1px solid #dcdddf;
					transition:			.25s ease-in-out;
					&:focus {
						outline:		none;
						box-shadow:		0 0 0 3px rgb(175,193,222, 0.8);
						border-color:	transparent;
					}
					&[readonly] {
						background-color:	#f4f5f7;
						color:				#6c748e;
						border-color:		transparent;
						pointer-events:		none;
					}
				}
				.action {
					text-align:			right;
					font-size:			11px;
					color:				rgba(0,0,0,0.5);
					padding:			5px 0;
					text-decoration:	underline;
				}
				.text-confirm {
					text-align:		right;
					font-size:		11px;
					color:			#039BE5;
					padding:		5px 0;
				}
				.text-warning {
					text-align:		right;
					font-size:		11px;
					color:			rgba(0,0,0,0.5);
					padding:		5px 0;
				}
			}
		}
	}
}

.secondary-email-container {
	.read-only {
		// background:		rgba(0, 0, 0, 0.1);
		border-radius:	5px;
		input {
			// background:		none !important;
			// border-radius:	5px 0 0 5px !important;
			// box-shadow:		2px 0px 24px rgba(0,0,0,.02);
		}
		div {
			display:		flex;
			align-items:	center;
			margin:			0 5px;
			opacity:		0.8;
			transition:		.25s ease-in-out;
			&:hover {
				opacity:	1;
			}
			.fa-edit {
				// margin:		0 5px;
			}
		}
	}
}
