.checkout-page {
	&[ng-click] {
		cursor: default;
	}
	.ui-offer-page {
		@carousel-height:					170px;
		@carousel-height-mobile:			158px;
		@thumbnail-wrapper-width:           43px;
		@thumbnail-wrapper-width-mobile:    40px;
		@thumbnail-width:                   36px;
		@thumbnail-width-mobile:            33px;
		@preview-width:                     ~'calc((100% - .5rem) - @{thumbnail-wrapper-width})';
		@preview-max-width: 				270px;
		@preview-max-width-mobile:          250px;
	
		.carousel-wrapper {
			width:              100%;
			height: 			@carousel-height;
			overflow: 			hidden;
			box-sizing: 		border-box;
			margin-bottom:      1.5rem;
			box-sizing:         border-box;
			background-color:   transparent;
			display:            flex;
			flex-flow:          row nowrap;
			align-items:        flex-start;
			justify-content:    flex-start;

			@media @tablet-landscape-down {
				height: @carousel-height-mobile;
			}
	
			@media @mobile-portrait-down {
				margin-bottom: 1rem;
			}
	
			&--no-thumbs {
				justify-content: center;
	
				.checkout-slider.checkout-slider-for {
					margin: auto;
				}
			}
		}
	
		.checkout-slider {
			// preview
			&.checkout-slider-for {
				width: 			@preview-width;
				max-width: 		@preview-max-width;
				height: 		@carousel-height;
				overflow: 		hidden;
				margin-right: 	.5rem;
				cursor:         default;
				border: 		none;

				@media @tablet-landscape-down {
					max-width: 	@preview-max-width-mobile;
					height: @carousel-height-mobile;
				}

				.card__image-wrapper {
					max-width: 		@preview-max-width;

					> div {
						border-radius: @default-border-radius;
					}

					@media @tablet-landscape-down {
						max-width: @preview-max-width-mobile;
					}
				}
			}
	
			// thumbnails
			&.checkout-slider-nav {
				width:  @thumbnail-wrapper-width;
				height: @carousel-height;
				overflow: hidden;

				@media @tablet-landscape-down {
					width: 	@thumbnail-wrapper-width-mobile;
					height: @carousel-height-mobile;
				}

				.card__image_repeater {
					border: 1px solid transparent;
				}
	
				.card__image-wrapper {
					width: @thumbnail-width;
					height: @thumbnail-width;
					margin: 0 auto;
					border-radius: 50%;
					border: 2px solid transparent;
					overflow: hidden;
					position: relative;
					z-index: 2;

					> img {
						display: inline-block;
						height: 100%;
					}

					@media @tablet-landscape-down {
						width: 	@thumbnail-width-mobile;
						height: @thumbnail-width-mobile;
					}
				}
	
				.slick-slide {
					outline: 	none;
					cursor: 	pointer;
					
					&.slick-current {
						.card__image-wrapper {
							border:     2px solid @blue-highlight;
						}
					}
				}

				.slick-track,
				.slick-list {
					height: @carousel-height !important;

					@media @tablet-landscape-down {
						height: @carousel-height-mobile !important;
					}
				}

				.slick-list,
				.slick-track,
				.slick-slide {
					box-sizing: border-box;
				}
			}
		}

		.checkout__tags {
			height: ~'calc(30px + 1rem)';
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-flow: row wrap;

			> * {
				margin-right: .5rem;
			}
		}
	
		.checkout__description {
			> * {
				&:extend(.description, p);
			}
	
			@media @mobile-portrait-down {
				margin-top:     1rem;
				margin-bottom:  0;
			}
		}
	
		.checkout__small {
			color: 			@blue-highlight;
			cursor: 		pointer;
			margin-top: 	.5rem;
			margin-bottom: 	1rem;
		}
	
		.checkout__subheading {
			display: flex;
			align-items: center;
			justify-content: flex-start;
	
			@media @mobile-portrait-down {
				margin-top: 1rem;
			}
	
			.discount-tag {
				margin-left: .5rem;
			}
		}
	
		.ui-form {
			.regular {
				padding:    .75rem 0;
				width:      inherit;
			}
			button {
				width:			100%;
				padding:		11px 0;
				background:		@blue-highlight;
				color:			@white;
				font-size:		18px;
				border:			none;
				border-radius:	10px;
				outline:		none;
				transition:		0.25s ease-in-out;
				&:hover {
					background: #128ef2;
				}
				@media @mobile-portrait-down {
					font-size:  14px;
				}
				&.secondary {
					font-size:          14px;
					background-color: 	transparent;
					color: 				#a2a3a7;
					box-shadow: 		none;
					&:hover {
						color:          @dark-grey-text-color;
						background: 	rgba(0,0,0,0.1);
					}
				}
			}
		}
	}
}

