body,
h1,
h2,
h3,
h4,
p,
small {
	margin:			0;
	font-family:	@default-font-family;
	color:			@default-text-color;
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
	line-height: 1.2;
}

p,
small,
span {
	font-weight: 400;
	line-height: 1.4;
}

strong {
	color:			inherit;
	font-size:		inherit;
	font-weight:	700;
	font-weight:	600;
}

*[class*="__title"] {
	font-size:		1.25rem;
	color: 			@light-grey-text-color;

	@media @tablet-portrait-down {
		font-size: .875rem;
	}
}

*[class*="__heading"] {
	font-size: 1.5rem;

	@media @tablet-portrait-down {
		font-size: 1.25rem;
	}
}

*[class*="__subheading"],
p {
	font-size: 1rem;

	@media @tablet-portrait-down {
		font-size: .875rem;
	}
}

*[class*="__name"] {
	font-size: 	.6rem;
	color: 		@light-grey-text-color;
}

small {
	font-size: .7rem;

	&[class*="__subheading"] {
		font-size: .875rem;
	}
}

&[class*="__meta"] {
	font-size: .6rem;

	&.--small {
		font-size: .5rem;
	}

	&.--large {
		font-size: .8rem;
	}
}

*[class*="__callout"] {
	font-size: 		.75rem;
	font-weight: 	600;
	color: 			inherit;
}

*[class*="__option"] {
	color:		@dark-grey-text-color;
	font-size:	.7rem;
}

.description,
*[class*="__description"] {
	font-family: 	@alternate-font-family;
	font-weight: 	400;
	color: 			@light-grey-text-color;
}
