.checkout-confirmation {
    .panel__content {
        justify-content: space-between !important;
    }
    .body {
        width:      100%;
        padding:    2rem 3rem 0;
        box-sizing: border-box;

        @media @mobile-portrait-down {
            padding:        0 2rem;
            margin-bottom:  7rem;
        }
        img {
            width:      100%;
            max-width:  75%;
            display:    block;

            @media @mobile-portrait-down {
                margin-bottom: 2rem;
            }
        }
    }

    .ui-form {
        border-top:     none;

    }
}
