.card {
	background-color:	@white;
	border-radius:		@default-border-radius;
	cursor: 			pointer;
	border: 			3px solid @light-grey-background-color;
	
	&.--selected {
		border: 3px solid @blue-highlight;
	}

	&:hover {
		.card__row--cta {
			border-color:		@blue-highlight;
			background-color:	@blue-highlight;

			* {
				color: @white;
			}
		}

		.card__row--filtered {
			border-color:		@selected-grey-background-color;
			background-color:	@selected-grey-background-color;

			* {
				color:	@white;
			}
		}
	}

	&__image-wrapper {
		position:		relative;
		margin:			0 auto;
		border-bottom:	none;

		.ui-offer-page & {
			border: none;
		}

		& > img {
			width:	100%;
		}

		& > div {
			position:				absolute;
			width:					100%;
			height:					100%;
			top:					0;
			left:					0;
			background-size:		cover;
			background-repeat:		no-repeat;
			background-position:	center center;
			box-sizing: 			border-box;
			border-radius:			@default-border-radius;

			.--selected & {
				border-radius: 6px 6px @default-border-radius @default-border-radius;
			}
		}
	}

	&__row {
		padding:			.625rem;
		display:			flex;
		flex-flow:			row nowrap;
		align-items:		center;
		justify-content:	space-between;

		@media @laptop-down {
			padding: .5rem;
		}

		&--cta {
			border-top:				1px solid @divider-border-color;
			background-color:		@white;
			transition:				all .3s ease-in-out;
			transition-property: 	background-color, border;
			cursor:					pointer;
			border-radius:			0 0 @default-border-radius @default-border-radius;

			.--selected & {
				border-radius: 0 0 6px 6px;
			}

			> * {
				font-size: .6rem;
			}
		}

		&--filtered {
			border-color:		@filtered-grey-background-color;
			background-color:	@filtered-grey-background-color;

			* {
				color:	@filtered-grey-color;
			}
		}

		+ .card__row:not(.card__row--cta) {
			padding-top: 0;
		}

		.discount-tag {
			margin-right: -.625rem;

			@media @laptop-down {
				margin-right: -.5rem;
			}
		}

		small {
			&:first-child {
				text-align:		left;
				margin-right:	.15rem;
			}
		}
	}

	&__name {
		width:				52%;
		height:				38px;
		display:			flex;
		align-items:		center;
		justify-content:	flex-start;
		text-align:			left;

		&--full-width {
			width: 			100%;
			margin-right: 	0;
		}

		span {
			width:			100%;
			overflow:		hidden;
			text-overflow:	ellipsis;

			* {
				font-size: 	inherit;
    			color: 		inherit;
			}
		}
	}

	&__icon {
		margin-left: .15rem;
	}
}
