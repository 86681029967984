.c-carousel {
	display:			flex;
	flex-direction:		column;
	justify-content: 	center;
	align-items: 		center;
	padding:			2.5rem;

	@media @split-screen-down {
		padding: 1.5rem;
	}

	.slick-track {
		display: 			flex;
		align-items: 		flex-end;
		justify-content: 	center;
	}

	&__img-wrapper {
		height:		100%;
		outline:	none;
	}

	&__image {
		margin:	0 auto 7rem;

		@media @split-screen-down {
			margin-bottom: 4rem;
		}
		@media @mobile-portrait-down {
			margin-bottom: 1rem;
		}

		&--full-width {
			max-width: 	100% !important;
			width: 		100% !important;
			height: 	auto !important;
			
			@media @mobile-portrait-down {
				margin-bottom: 5rem;
			}
		}
	}

	.slick-slide {
		outline: 		none;
		will-change: 	transform;

		img {
			width:		100%;
			max-width: 	230px;
		}
	}

	.slick-prev {
		display:	none !important;
	}

    .slick-dots {
		display:			flex;
		justify-content: 	center;
		list-style-type: 	none;
		margin:				0;
		padding: 			0;
		line-height:		10px;
		text-align:			center;
		li {
			button {
				padding:	0 2px;
                background:	none;
				font-size: 	9px;
				color:		#0214311a;
				border:		0;
				outline:	none;
				&:focus {
					outline: none;
				}
				&:hover {
					cursor:	pointer;
				}
			}
			&.slick-active {
				button {
					color:		#00a7ff;
				}
			}
		}
    }
}

.slick-controls {
	width:			100%;
	padding:		0 2.5rem 2.5rem;
	box-sizing: 	border-box;
	@media @split-screen-down {
		margin:		1rem 0 0;
		padding:	0 1.5rem 1.5rem;
	}

	button {
		width:			100%;
		padding:		10px;
		background:		#00a7ff;
		color:			#ffffff;
		font-size:		18px;
		border:			none;
		border-radius:	10px;
		outline:		none;
		&:hover {
			background:	#128ef2;
		}
		@media @tablet-portrait-down {
			font-size:  14px;
		}
		&.secondary {
			background-color: 	transparent;
			font-size:			14px;
			color: 				#a2a3a7;
			box-shadow: 		none;
			display: 			none;
			@media @split-screen-down {
				margin-top:	.5rem;
				display:		inline-block;
			}
			&:hover {
				color:          @dark-grey-text-color;
				background: 	rgba(0,0,0,0.1);
			}
		}
	}
}


/* 
	Default Slick Slider CSS
*/

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
