.offset-padding(@offset) {
	@base-padding: 1rem;

	padding: @base-padding @offset;
}

a {
	text-decoration:	none;
	color:				@blue-highlight;
};

[ng-click] {
	cursor:	pointer;
}

.sandbox-env {
	position:		absolute;
	z-index:		50000;
	bottom:			0;
	right:			0;
	color:			#ff0000;
	font-size:		10px;
	font-weight:	500;
	padding:		5px;
}

.loading-full-screen {
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	background-color:	rgba(255,255,255, 1);
	padding:	10px 30px;
	text-align:	center;
}

.prizeout-widget {
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	font-family: 'Lato', 'Roboto', sans-serif;
	font-weight: 400;
	background: rgba(252, 253, 255, 1);
	padding:	env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

	.ui-header {
		background-color:	@blue-highlight;
		color:				@white;
		font-weight:		400;
		font-size:			1rem;
		box-sizing: 		border-box;
		width: 				100%;
		z-index: 			51000;
		position: 			relative;

		&__inner {
			.offset-padding(3rem);

			display: 			flex;
			align-items: 		center;
			justify-content: 	space-between;
			flex-flow: 			row nowrap;
			width:				100%;
			margin: 			0 auto;
			box-sizing: 		border-box;

			@media @monitor-up {
				.offset-padding(9rem);
			}

			@media @desktop-down {
				.offset-padding(2rem);
			}

			@media @tablet-landscape-down {
				.offset-padding(1.875rem);
			}

			@media @tablet-portrait-down {
				padding: .7rem 1rem;
			}

			> div {
				flex: 1;
				width: 100%;
			}
		}

		

		.back {
			text-align: left;

			&__icon {
				color:			@white;
				font-size:		1.5rem;
				transition:		all 0.3s ease-in-out;

				&:hover {
					color:		@default-text-color;
				}
			}
		}

		.logo {
			text-align: center;

			&__image {
				width: 		100%;
				max-width: 	140px;
				height: 	auto;
				display: 	inline-block;

				&.--mobile {
					max-width: 20px;
				}
			}
		}

		.user {
			display: 			inline-flex;
			align-items: 		center;
			justify-content: 	flex-end; 

			&__balance {
				background-color:	@white;
				border-radius:		30px;
				font-family:		@alternate-font-family;
				padding:			.25rem .9rem;
				margin-right:		.75rem;
				display:			inline-flex;
				align-items:		center;
				justify-content:	center;

				p {
					color: 		@default-text-color;
					font-size: 	1rem;

					@media @split-screen-down {
						font-size: .875rem;
					}

					@media @mobile-portrait-down {
						font-size: .65rem;
					}
				}
			}

			&__bars {
				color:		@white;
				font-size:	1.5rem;

				@media @mobile-portrait-down {
					font-size: 1.2rem;
				}
			}
		}
	}

	& > .ui-content {
		overflow-y:			auto;
		overflow-x:			hidden;
		color:				@default-text-color;
		background-color:	#f2f3f4;
		-webkit-overflow-scrolling: touch;

		& > .content-container {
			transition:			0.3s ease-out all;
			padding-right:		0;
		}
	}
}
.scroll-pane {
	width: 		100%;
	height: 	100%;
	margin: 	0;
	padding: 	0;
}

.side-panel-layout {
	width: 				100%;
	height: 			100%;
	overflow-y: 		auto;
	padding: 			3rem;
	box-sizing: 		border-box;
	background-color: 	@light-grey-background-color;
	transition: 		0.3s ease-in all;
	overflow-x: 		hidden;

	@media @monitor-up {
		padding: 3rem 9rem;
	}

	@media @desktop-down {
		padding:	3rem 2rem;
	}

	@media @tablet-landscape-down {
		padding:	3rem 1.875rem;
	}

	@media @tablet-portrait-down {
		padding:	2rem 1rem;
	}
	
	& > div {
		& > div {
			&.ui-content {
				color:				@default-text-color;
				background-color:	@light-grey-background-color;
				-webkit-overflow-scrolling: touch;
			}
			&.side-panel-container {
				transition:					0.3s ease-out all;
				width:						0;
				-webkit-overflow-scrolling: touch;
			}
		}
	}
	&.side-panel-open {
		width: @content-width;
		padding-right:	2.25rem;

		@media @monitor-up {
			padding-right: 5rem;
		}

		@media @desktop-down {
			padding-right: 2.5rem;
		}

		@media @tablet-landscape-down {
			padding-right: 1.875rem;
		}

		@media @tablet-portrait-down {
			padding-right: 1rem;
		}

		& > div {
			& > div {
				&.side-panel-container {
					width:	@side-panel-width;
				}
			}
		}
	}
}

.ui-footer {
	text-align:			center;
	padding:			10px 25px;
	background-color:	rgba(34, 34, 34, 1);
	.partner-text {
		font-size:		6px;
		font-weight:	500;
		color:			rgba(255,255,255,0.8);
	}
	.footer-menu {
		.item {
			display:			inline-block;
			vertical-align:		middle;
			font-size:			9px;
			font-weight:		500;
			color:				rgba(255,255,255,0.8);
		}
		.sep {
			display:			inline-block;
			vertical-align:		middle;
			width:				4px;
			height:				4px;
			border-radius:		50%;
			margin:				0 5px;
			background-color:	rgba(255,255,255,0.8);
		}
	}
	.data-request {
		display:			inline-block;
		vertical-align:		middle;
		font-size:			9px;
		font-weight:		500;
		color:				rgba(255,255,255,0.8);
	}
}

.ng-highlight {
	background-color:	rgba(66, 165, 245, 0.3);
}



/*
	OneTrust
*/
button {
	margin:	0;
}
.ot-btn-anchor {
	text-decoration: none;
}
.ot-exercise-button-light {
	background: #eee;
	border: 1px solid #aaa;
	/*margin-left: 44px;*/
}
.ot-exercise-button {
	margin-top: 10px;
	padding: 6px 10px;
	border-radius: 5px;
	cursor: pointer;
	img {
		margin-right: 0px;
		vertical-align: middle;
	}
	.ot-text-container {
		vertical-align: middle;
		display: inline-block;
		margin-top: -4px;
		color: #6cc04a;
		font-size: 13px;
	}
	.ot-subtext {
		float: right;
		margin-top: 0px;
		font-size: 11px;
	}
	.ot-powered-by-text {
		font-size: 7px;
		font-family: 'Open Sans';
		-webkit-font-smoothing: antialiased;
		vertical-align: top;
		line-height: 16px;
	}
}
.ot-exercise-button.ot-exercise-button-light {
	.ot-powered-by-text {
		color: #3c474c;
	}
}



table.my-giftcards {
	width:			100%;
	& > tbody {
		& > tr {
			& > td {
				padding:	10px 5px;
				&.image {
					width:	100px;
					& > .giftcard-image {
						width: 					123px;
						height: 				78px;
						position: 				relative;
						margin:					0 auto;
						border-radius: 			10px;
						border:					1px solid #dcdddf;
						background-size: 		cover;
						background-repeat: 		no-repeat;
						background-position: 	center center;
					}
				}
				&.gc-name {
					display:			flex;
					flex-direction: 	column;
					justify-content: 	space-between;
					margin:				12px 0;
					color:				#4f566b;
					font-size:			12px;
					font-weight:		500;

					.name {
						display:			flex;
						flex-wrap:			wrap;
						justify-content: 	space-between;
						align-items: 		center;
						margin-bottom:		10px;
						font-size:			13px;
						font-weight:		500;
					}
					.detail {
						display:			flex;
						justify-content:	space-between;
						align-items: 		center;
						.when {
							width:			100%;
							display:		flex;
							flex-direction: column;
							padding:		8px 0;
							font-size:		10px;
							font-weight:	300;
							border-right:	1px solid #e1e8eb;
							& > span {
								margin-bottom:	5px;
							}
						}
						.value {
							width:			40%;
							display:		flex;
							align-items: 	center;
							padding:		0 8px;
							font-size:		16px;
							& > span {
								width:				80%;
								margin-right:		10px;
							}
							.fa-caret-right, .fa-caret-down {
								font-size:		20px;
							}
						}
					}
				}
				&.status {
					text-align:	center;
					a {
						font-size:		10px;
						font-weight:	300;
						color:			rgba(0,0,0,0.7);
					}
				}
			}
			&.last {
				td {
					border-bottom:		1px solid #6c748e;
				}
			}
		}
	}
}
table.transaction-detail {
	width:			100%;
	padding:		5px;
	& > tbody {
		tr {
			font-size:	12px;
			td {
				padding-bottom:	5px;
				&:first-child {
					color:			rgba(0, 0, 0, 0.5);
					text-align:		right;
				}
				&.detail {
					color:			#4f566b;
				}
			}
		}
	}
}

.text-link {
	color:				#1eb1fa;
	text-decoration:	underline;
}
